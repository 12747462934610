import React, { useState } from "react";

const InfoForm = ({ setPersonalData }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedFormData);
    setPersonalData(updatedFormData);
  };
  
  

  console.log(formData);

  return (
    <div className="row">
      <div className="col-12">
        <div className="contact-form--1">
          <div className="container">
            <div className="row row--35 align-items-start">
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="section-title text-left mb--50">
                  <h4
                    className="title"
                    style={{
                      marginTop: "3rem",
                    }}
                  >
                    Please Enter Your Personal Information.
                  </h4>
                </div>
                <div className="form-wrapper">
                  <form>
                    <div className="rn-form-group">
                      <input
                        type="text"
                        name="name"
                        placeholder="Enter Your Full Name"
                        required
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="rn-form-group">
                      <input
                        type="email"
                        name="email"
                        placeholder="Enter Your Email"
                        required
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="rn-form-group">
                      <input
                        type="tel"
                        name="phone"
                        placeholder="Enter Your Phone Number"
                        required
                        value={formData.phone}
                        onChange={handleInputChange}
                      />
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2">
                {/* You can add a thumbnail or any other content here */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoForm;
