import React from "react";
import {
    FiCast,
    FiLayers,
    FiUsers,
    FiMonitor,
  } from "react-icons/fi";

import Service1 from "../../../public/assets/StormRoofer/Service1.webp";
import Service2 from "../../../public/assets/StormRoofer/Service2.webp";
import Service3 from "../../../public/assets/StormRoofer/Service3.webp";
import Service4 from "../../../public/assets/StormRoofer/Service4.webp";
import Service5 from "../../../public/assets/StormRoofer/Service5.webp";
import Service6 from "../../../public/assets/StormRoofer/Service6.webp";
import Service7 from "../../../public/assets/StormRoofer/Service7.webp";

const ServiceList = [
    {
      icon: <FiCast />,
      id:"roof-installation",
      background: Service1,
      title: "Roof Installation",
      description:
        "We offer professional roof installation services to provide our clients with a sturdy and durable roof that perfectly fits their needs. Our team of skilled technicians ensures a flawless installation process, using high-quality materials and advanced techniques to deliver exceptional results.",
    },
    {
      icon: <FiLayers />,
      id:"roof-repair",
      background: Service2,
      title: "Roof Repair",
      description:
        "We specialize in efficient and reliable roof repair services. Our experienced technicians thoroughly evaluate the extent of damage and promptly address any issues such as leaks, missing shingles, or damaged flashing. With our expertise, we strive to restore the integrity of your roof and ensure long-lasting protection.",
    },
    {
      icon: <FiUsers />,
      id:"roof-replacement",
      background: Service3,
      title: "Roof Replacement",
      description:
        "When your roof reaches the end of its lifespan or suffers extensive damage, Storm Saver Roofing is here to provide reliable roof replacement services. Our professionals will assess the condition of your roof and offer suitable replacement options. With our attention to detail and commitment to quality, we guarantee a seamless replacement that enhances the appearance and functionality of your property.",
    },
    {
      icon: <FiMonitor />,
      id:"roof-maintenance",
      background: Service4,
      title: "Roof Maintenance",
      description:
        "Comprehensive roof maintenance services tailored to your specific needs. Regular inspections, cleaning, and preventive maintenance help identify potential problems early on, ensuring a longer lifespan for your roof and minimizing the risk of unexpected repairs. Trust our expert team to keep your roof in optimal condition.",
    },
    {
      icon: <FiCast />,
      id:"leak-detection-and-repair",
      background: Service5,
      title: "Leak Detection and Repair",
      description:
        "Leaks can be a major source of frustration and damage to your property. At stormsaverroofing.com, we specialize in leak detection and repair. Our skilled technicians use the latest techniques and equipment to locate the source of the leak accurately. Once identified, we promptly repair the issue, ensuring that your roof remains watertight and protected.",
    },
    {
      icon: <FiMonitor />,
      id:"gutter-installation",
      background: Service6,
      title: "Gutter Installation",
      description:
        "Properly functioning gutters are essential for directing water away from your property, preventing water damage and erosion. At Storm Saver Roofing, we provide expert gutter installation services. Our team will assess your property's needs and install high-quality gutters that effectively channel water away, protecting your roof and foundation.",
    },
    {
      icon: <FiMonitor />,
      id:"gutter-cleaning",
      background: Service7,
      title: "Gutter Cleaning",
      description:
        "we understand the importance of clean and well-maintained gutters to protect your property during storms. Our specialized gutter cleaning services ensure that your gutters are free from debris, leaves, and other materials that can cause clogs and damage. With our thorough cleaning techniques, we ensure the efficient flow of rainwater and prevent potential water damage to your roof and foundation.",
    },
  ];

  export default ServiceList;