import React, { useEffect, useState } from "react";
import styles from "./DesignsDisplay.module.css";

const RoofDesign = () => {
  const [designs, setDesigns] = useState([]);
  const [currentImage, setCurrentImage] = useState(
    ""
  );

  useEffect(() => {
    const fetchDesign = async () => {
      const response = await fetch(
        "https://be.stormsaverroofing.mohamedradwan.me/api/visitor/design?getType=list"
      );

      if (!response.ok) {
        throw new Error("Something went wrong!");
      }

      const responseData = await response.json();
      const dataArray = responseData.data;
      const loadedDesigns = [];

      for (const key in dataArray) {
        loadedDesigns.push({
          id: key,
          name: dataArray[key].name,
          imageUrl: dataArray[key].images[0].url,
        });
      }

      setDesigns(loadedDesigns);
      const firstImageUrl = loadedDesigns[0]?.imageUrl; 
        if (firstImageUrl) {
          setCurrentImage(
            `https://be.stormsaverroofing.mohamedradwan.me/${firstImageUrl}`
          );
        }
    };
    

    fetchDesign().catch((error) => {});
  }, []);
  console.log(designs);
  const imagesArray = [];
  for (const key in designs) {
    imagesArray.push({
      imageUrl: designs[key].imageUrl,
    });
  }

  const handleImageClick = (imageUrl) => {
    setCurrentImage(
      `https://be.stormsaverroofing.mohamedradwan.me/${imageUrl}`
    );
  };

  const imagesUrl = imagesArray.map((image, index) => (
    <div>
      <img
        key={index}
        src={`https://be.stormsaverroofing.mohamedradwan.me/${image.imageUrl}`}
        alt="design image"
        onClick={() => handleImageClick(image.imageUrl)}
      />
    </div>
  ));

  console.log(imagesArray);

  console.log(imagesUrl);

  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <img src={currentImage} />
      </div>
      <div className={styles.leftContainer}>
        <div className={styles.header}>
          <p>TruDefinition® Duration® Shingles</p>
          <h5>Color Options</h5>
        </div>
        <div className={styles.imagesSamples}>{imagesUrl}</div>
        <div className={styles.description}>
          <p>
            Our commitment is to provide accurate representations of all
            shingles across various publications and on-screen displays. The
            roofing product colors you see are as faithful as technology
            permits. To gain a more accurate perception of the true colors, we
            recommend visiting a roofing display or examining multiple shingles.
            Prior to making your final color decision, we advise assessing your
            shingle and color choices on an actual roof with a pitch similar to
            your own, ensuring a proper evaluation.
          </p>
        </div>
      </div>
    </div>
  );
};

export default RoofDesign;
