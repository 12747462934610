import React, { Component } from "react";
import styles from "./AboutTwo.module.css";

class AboutTwo extends Component {
  render() {
    return (
      <React.Fragment>
        <div className={styles.container}>
          <div className={styles.leftSection}>
            <img
              src="/assets/images/about/why-choose-us1.png"
              alt="About Images"
            />
            <img
              src="/assets/images/about/why-choose-us2.png"
              alt="About Images"
            />
          </div>

          <div className={styles.rightSection}>
            <h3 className="title">WHY CHOOSE US</h3>
            <p>
              At Storm Saver Roofing Company, we take pride in being the best
              roofing company in Lexington and all over Kentucky. Our commitment
              to providing top-notch roofing solutions sets us apart from the
              competition. With years of experience and a team of highly skilled
              professionals, we guarantee quality roofing services that will
              exceed your expectations. Whether you need roof repairs or a
              complete installation, we have the expertise to handle any
              project, big or small.
            </p>
            <p>
              What makes us the best roofing company in the area is our
              dedication to customer satisfaction. We understand that your home
              is one of your most valuable assets, and we treat every project
              with utmost care and precision. Our team of experts is equipped
              with the latest tools and techniques to ensure that your roof is
              expertly installed and built to withstand even the harshest
              weather conditions.
            </p>
            <p>
              When it comes to roofing, don't settle for anything less than the
              best. Choose Storm Saver Roofing Company for all your roofing
              needs,
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default AboutTwo;
