import Carousel from "react-bootstrap/Carousel";

import React from "react";

const HomeCarousel = () => {
  return (
    <Carousel>
      <Carousel.Item style={{ width: "100%", textAlign: "center" }}>
        <img
          src="/assets/images/carousel/carousel-1.png"
          alt="design one "
          style={{ height: "212px", width: "33.3%" }}
        />
        <img
          src="/assets/images/carousel/carousel-2.png"
          alt="design one "
          style={{ height: "212px", width: "33.3%" }}
        />
        <img
          src="/assets/images/carousel/carousel-3.png"
          alt="design one "
          style={{ height: "212px", width: "33.3%" }}
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          src="/assets/images/carousel/carousel-4.png"
          alt="design one "
          style={{ height: "212px", width: "33.3%" }}
        />
        <img
          src="/assets/images/carousel/carousel-5.png"
          alt="design one "
          style={{ height: "212px", width: "33.3%" }}
        />
        <img
          src="/assets/images/carousel/carousel-6.png"
          alt="design one "
          style={{ height: "212px", width: "33.3%" }}
        />
      </Carousel.Item>
    </Carousel>
  );
};

export default HomeCarousel;
