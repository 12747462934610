import React from "react";
import styles from "./AboutThree.module.css";

const AboutThree = () => {
  return (
    <div className={styles.container}>
      <p>Contact Us</p>
      <h3>WE ARE SET UP TO ASSIST YOU!</h3>
      <p>
        <em>Storm Saver</em> aims to be a hassle-free and delightful solution
        for obtaining a brand-new roof.
      </p>
    </div>
  );
};

export default AboutThree;
