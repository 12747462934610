import React, { Component, Fragment } from "react";
import Scrollspy from "react-scrollspy";
import ScrollToTop from "react-scroll-up";
import {
  FiChevronUp,
  FiX,
  FiMenu,
  FiCast,
  FiLayers,
  FiUsers,
  FiMonitor,
} from "react-icons/fi";

import Helmet from "../../component/common/Helmet";
import ContactTwo from "../../elements/contact/ContactTwo";
import AboutTwo from "../../component/HomeLayout/homeOne/AboutTwo";
import slideBackground from "../../../public/assets/StormRoofer/slideBackground.webp";

import Service1 from "../../../public/assets/StormRoofer/Service1.webp";
import Service2 from "../../../public/assets/StormRoofer/Service2.webp";
import Service3 from "../../../public/assets/StormRoofer/Service3.webp";
import Service4 from "../../../public/assets/StormRoofer/Service4.webp";
import Service5 from "../../../public/assets/StormRoofer/Service5.webp";
import Service6 from "../../../public/assets/StormRoofer/Service6.webp";
import ModalVideo from "react-modal-video";
import { Link } from "react-router-dom/cjs/react-router-dom";
import SliderOne from "../../component/slider/SliderOne";
import Posts from "./posts/Posts";
const SlideList = [
  {
    textPosition: "text-center",
    category: "",
    title: "EXCELLENCE IN ROOFING, KENTUCKY’S BEST ROOFING COMPANY.",
    description:
      "All of your home's exterior needs are taken care of by Storm Saver. Our skilled team is available to assist you whether you need new siding, gutters, or a roof replacement.",
    buttonText: "Contact Us",
    buttonLink: "/contact",
  },
];
const ServiceList = [
  {
    icon: <FiCast />,
    background: Service1,
    title: "Roof Installation",
    description:
      "We offer professional roof installation services to provide our clients with a sturdy and durable roof that perfectly fits their needs. Our team of skilled technicians ensures a flawless installation process, using high-quality materials and advanced techniques to deliver exceptional results.",
  },
  {
    icon: <FiLayers />,
    background: Service2,
    title: "Roof Repair",
    description:
      "We specialize in efficient and reliable roof repair services. Our experienced technicians thoroughly evaluate the extent of damage and promptly address any issues such as leaks, missing shingles, or damaged flashing. With our expertise, we strive to restore the integrity of your roof and ensure long-lasting protection.",
  },
  {
    icon: <FiUsers />,
    background: Service3,
    title: "Roof Replacement",
    description:
      "When your roof reaches the end of its lifespan or suffers extensive damage, Storm Saver Roofing is here to provide reliable roof replacement services. Our professionals will assess the condition of your roof and offer suitable replacement options. With our attention to detail and commitment to quality, we guarantee a seamless replacement that enhances the appearance and functionality of your property.",
  },
  {
    icon: <FiMonitor />,
    background: Service4,
    title: "Roof Maintenance",
    description:
      "Comprehensive roof maintenance services tailored to your specific needs. Regular inspections, cleaning, and preventive maintenance help identify potential problems early on, ensuring a longer lifespan for your roof and minimizing the risk of unexpected repairs. Trust our expert team to keep your roof in optimal condition.",
  },
  {
    icon: <FiCast />,
    background: Service5,
    title: "Leak Detection and Repair",
    description:
      "Leaks can be a major source of frustration and damage to your property. At stormsaverroofing.com, we specialize in leak detection and repair. Our skilled technicians use the latest techniques and equipment to locate the source of the leak accurately. Once identified, we promptly repair the issue, ensuring that your roof remains watertight and protected.",
  },
  {
    icon: <FiMonitor />,
    background: Service6,
    title: "Gutter Installation",
    description:
      "Properly functioning gutters are essential for directing water away from your property, preventing water damage and erosion. At Storm Saver Roofing, we provide expert gutter installation services. Our team will assess your property's needs and install high-quality gutters that effectively channel water away, protecting your roof and foundation.",
  },
];

class BlogPage extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.stickyHeader = this.stickyHeader.bind(this);
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }
  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }
  stickyHeader() {}
  render() {
    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 50) {
        document.querySelector(".header--fixed").classList.add("sticky");
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky");
      }
    });

    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
    return (
      <Fragment>
        <Helmet pageTitle="Storm Roofer" />

        {/* Start Header Area  */}
        <header className="header-area formobile-menu header--fixed default-color">
          <div className="header-wrapper" id="header-wrapper">
            <div className="header-left">
              <div className="logo">
                <a href="/">
                  <img
                    className="logo-1"
                    src="/assets/images/logo/new-logo.png"
                    alt="Logo Images"
                  />
                  <img
                    className="logo-2"
                    src="/assets/images/logo/new-logo.png"
                    alt="Logo Images"
                  />
                </a>
              </div>
            </div>
            <div className="header-right">
              <nav className="mainmenunav d-lg-block">
                <Scrollspy
                  className="mainmenu"
                  items={["home", "bookOnline", "Blog", "Instance"]}
                  currentClassName="is-current"
                  offset={-200}
                >
                  <li>
                    <Link to="/storm-roofer">Home</Link>
                  </li>
                  <li>
                    <Link to="/storm-roofer/book-online" href="#service">
                      Book Online
                    </Link>
                  </li>
                  <li>
                    <Link to="/storm-roofer/blog" href="#getstart">
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link to="/storm-roofer/instance" href="#about">
                      Instance
                    </Link>
                  </li>
                </Scrollspy>
              </nav>
              <div className="header-btn">
                <a
                  className="rn-btn"
                  // href="https://themeforest.net/checkout/from_item/25457315?license=regular"
                >
                  <div style={{ display: "flex", gap: "15px" }}>
                    <span>Contact Us </span>
                    <span> +1 502-977-4935</span>
                  </div>
                </a>
              </div>
              {/* Start Humberger Menu  */}
              <div className="humberger-menu d-block d-lg-none pl--20">
                <span
                  onClick={this.menuTrigger}
                  className="menutrigger text-white"
                >
                  <FiMenu />
                </span>
              </div>
              {/* End Humberger Menu  */}
              <div className="close-menu d-block d-lg-none">
                <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                  <FiX />
                </span>
              </div>
            </div>
          </div>
        </header>
        {/* End Header Area  */}

        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          <SliderOne />
        </div>
        <div>
          <Posts />
        </div>
        {/* End Slider Area   */}
        

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}

export default BlogPage;
