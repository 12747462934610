import React, { useState } from "react";
import DUMMY_POSTS from "./DUMMY_POSTS";
import PostsDisplay from "./PostsDisplay";
import styles from "./Posts.module.css";
import { Link } from "react-router-dom";

const Posts = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const filteredPosts = DUMMY_POSTS.filter((post) =>
    post.header.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const posts = filteredPosts.map((post) => (
    <Link to={`/post-${post.id}`} key={post.id}>
      <PostsDisplay
        id={post.id}
        creatorImg={post.creator.image}
        creatorName={post.creator.name}
        creatorDate={post.creator.date}
        creatorTime={post.creator.time}
        postImg={post.postImage}
        postHeader={post.header}
        postContent={post.content}
        postViews={post.footer.views}
        postComments={post.footer.comments}
      />
    </Link>
  ));

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  return (
    <div className={styles.container}>
      <div className={styles.navSection}>
        <h3 className={styles.header}>All Posts</h3>
        <input
          type="text"
          placeholder="Search posts..."
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>
      {posts.length > 0 ? (
        posts
      ) : (
        <p>No posts found matching the search criteria.</p>
      )}
    </div>
  );
};

export default Posts;
