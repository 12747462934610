import React, { useEffect, useState } from "react";
import useFetch from "../../../../hooks/useFetch";
import Card from "../Card";
import styles from "../building/Building.module.css";

const Designs = ({ materialId, brandId, typeId ,setCurrentDesignId}) => {
  const [designData, setDesignData] = useState([]);
  const [designId, setDesignId] = useState("");

  const { data: DesignData } = useFetch(
    `design?material=${materialId}&brand=${brandId}&type=${typeId}&getType=list`
  );
  console.log(DesignData[0]);

  useEffect(() => {
    const loadedDesigns = [];

    if (DesignData) {
      for (const key in DesignData) {
        const images = DesignData[key].image.map(img => img.url);
        loadedDesigns.push({
          id: DesignData[key].id,
          images: images,
          type: DesignData[key].name,
        });
      }
      setDesignData(loadedDesigns);
    } else {
      console.log("No Types available");
    }
  }, [DesignData]);
  console.log(designData);

  const handleCardClick = (id) => {
    setDesignId(id);
    setCurrentDesignId(id);
  };

  const designCards = designData.map((design) => (
    <Card
      key={design.id}
      id={design.id}
      title={design.type}
      images={design.images}
      rate={design.rate}
      isSelected={design.id === designId}
      onClick={() => handleCardClick(design.id)}
    />
  ));

  return <div className={styles.container}>{designCards}</div>;
};

export default Designs;
