import React, { useState } from "react";
import YesImage from "../../../../public/assets/images/StormRoof/check_1828743.png";
import NoImage from "../../../../public/assets/images/StormRoof/close_2976286.png";
import MaybeImage from "../../../../public/assets/images/StormRoof/question-mark_57108.png";

const Financing = ({setProjectFinancing}) =>{
    const [Financing, setFinancing] = useState("");

    return(
        <div className="row">
                    <div
                      className="col-lg-4 col-md-6 col-sm-6 col-12 "
                      style={{ cursor: "pointer" }}
                      onClick={() => (setProjectFinancing("Yes") , setFinancing("Yes")) }
                    >
                      <div
                        className={
                          "service service__style--2" +
                          (Financing === "Yes" ? "  __activeCard" : "")
                        }
                      >
                        <div className="content">
                          <h3 className="title">Yes</h3>
                        </div>
                        <div className="icon">
                          <img
                            src={YesImage}
                            alt="icon"
                            style={{
                              width: 25,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-4 col-md-6 col-sm-6 col-12"
                      style={{ cursor: "pointer" }}
                      onClick={() => (setProjectFinancing("No") , setFinancing("No"))}
                    >
                      <div
                        className={
                          "service service__style--2" +
                          (Financing === "No" ? "  __activeCard" : "")
                        }
                      >
                        <div className="content">
                          <h3 className="title">No</h3>
                        </div>
                        <div className="icon">
                          <img
                            src={NoImage}
                            alt="icon"
                            style={{
                              width: 25,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-4 col-md-6 col-sm-6 col-12"
                      style={{ cursor: "pointer" }}
                      onClick={() => (setProjectFinancing("Maybe") , setFinancing("Maybe"))}
                    >
                      <div
                        className={
                          "service service__style--2" +
                          (Financing === "Maybe" ? "  __activeCard" : "")
                        }
                      >
                        <div className="content">
                          <h3 className="title">Maybe</h3>
                        </div>
                        <div className="icon">
                          <img
                            src={MaybeImage}
                            alt="icon"
                            style={{
                              width: 25,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
    )
};


export default Financing;