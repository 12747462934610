import React, { useState, useEffect } from "react";
import useFetch from "../../../../hooks/useFetch";
import Card from "../Card";
import styles from "../building/Building.module.css";

const Steep = ({ setSteepType }) => {
  const [steep, setSteep] = useState([]);
  const [steepId, setSteepId] = useState("");

  const { data: SteepData, isLoading: SteepIsLoading } =
    useFetch("steep");

  useEffect(() => {
    const loadedProducts = [];

    if (SteepData && !SteepIsLoading) {
      for (const key in SteepData) {
        loadedProducts.push({
          id: SteepData[key].id,
          image: SteepData[key].images[0].url,
          type: SteepData[key].type,
        });
      }
      setSteep(loadedProducts);
      console.log(steep);
    } else {
      console.log("No products available");
    }
  }, [SteepData, SteepIsLoading]);

  const handleCardClick = (id) => {
    setSteepId(id);
    setSteepType(id);
  };

  const test = steep.map((m) => (
    <Card
      key={m.id}
      id={m.id}
      title={m.type}
      image={m.image}
      isSelected={m.id === steepId}
      onClick={handleCardClick}
    />
  ));
  
  return (
  <div className={styles.container}>
      {test}
  </div>);
};

export default Steep;
