import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tabs, Tab } from "react-bootstrap";
import Building from "./building/Building";
import Steep from "./steep/Steep";
import CurrentRoof from "./currentRoof/CurrentRoof";
import DesiredRoof from "./desiredRoof/DesirdRoof";
import EstimatedDate from "./estimatedDate/EstimatedDate";
import ProjectDetails from "./details/ProjectDetails";
import Financing from "./financing/Financing";
import Map from "./map/Map";
import InfoForm from "./form/InfoForm";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";

const Step1Content = ({ setBuildingType }) => (
  <div>
    <Building setBuildingType={setBuildingType} />
  </div>
);

const Step2Content = ({ setSteepType }) => (
  <div>
    <Steep setSteepType={setSteepType} />
  </div>
);

const Step3Content = ({
  setCurrentMaterialId,
  setCurrentBrandId,
  setCurrentTypeId,
  setCurrentDesignId,
}) => (
  <div>
    <CurrentRoof
      setCurrentMaterialId={setCurrentMaterialId}
      setCurrentBrandId={setCurrentBrandId}
      setCurrentTypeId={setCurrentTypeId}
      setCurrentDesignId={setCurrentDesignId}
    />
  </div>
);

const Step4Content = ({
  setDesiredMaterialId,
  setDesiredBrandId,
  setDesiredTypeId,
  setDesiredDesignId,
  setDesiredDesignPrice,
}) => (
  <div>
    <DesiredRoof
      setDesiredMaterialId={setDesiredMaterialId}
      setDesiredBrandId={setDesiredBrandId}
      setDesiredTypeId={setDesiredTypeId}
      setDesiredDesignId={setDesiredDesignId}
      setDesiredDesignPrice={setDesiredDesignPrice}
    />
  </div>
);

const Step5Content = ({ setWorkPlan }) => (
  <div>
    <EstimatedDate setWorkPlan={setWorkPlan} />
  </div>
);

const Step6Content = ({ setProjectDescription }) => (
  <div>
    <ProjectDetails setProjectDescription={setProjectDescription} />
  </div>
);

const Step7Content = ({ setProjectFinancing }) => (
  <div>
    <Financing setProjectFinancing={setProjectFinancing} />
  </div>
);

const Step8Content = ({
  setMapCenter,
  setDesiredArea,
  desiredDesignPrice,
  setTotalPrice,
}) => (
  <div>
    <Map
      setMapCenter={setMapCenter}
      setDesiredArea={setDesiredArea}
      desiredDesignPrice={desiredDesignPrice}
      setTotalPrice={setTotalPrice}
    />
  </div>
);

const Step9Content = ({ setPersonalData }) => {
  return (
    <div>
      <InfoForm setPersonalData={setPersonalData} />
    </div>
  );
};

const MultiStepTabs = () => {
  const [key, setKey] = useState("step1");
  const [buildingType, setBuildingType] = useState("");
  const [steepType, setSteepType] = useState("");
  const [currentMaterialId, setCurrentMaterialId] = useState("");
  const [currentBrandId, setCurrentBrandId] = useState("");
  const [currentTypeId, setCurrentTypeId] = useState("");
  const [currentDesignId, setCurrentDesignId] = useState("");
  const [desiredMaterialId, setDesiredMaterialId] = useState("");
  const [desiredBrandId, setDesiredBrandId] = useState("");
  const [desiredTypeId, setDesiredTypeId] = useState("");
  const [desiredDesignId, setDesiredDesignId] = useState("");
  const [desiredDesignPrice, setDesiredDesignPrice] = useState("");
  const [totalPrice, setTotalPrice] = useState("");
  const [mapCenter, setMapCenter] = useState({});
  const [desiredArea, setDesiredArea] = useState("");
  const [workPlan, setWorkPlan] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [projectFinancing, setProjectFinancing] = useState("");
  const [personalData, setPersonalData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [selectedTab, setSelectedTab] = useState(0);
  const tabCount = 9;
  const [errors, setErrors] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);


  console.log(projectDescription);
  const handleBack = () => {
    const newIndex = (selectedTab + tabCount - 1) % tabCount;
    setKey(`step${newIndex + 1}`);
    setSelectedTab(newIndex);
  };

  const handleNext = () => {
    const newIndex = (selectedTab + 1) % tabCount;
    setKey(`step${newIndex + 1}`);
    setSelectedTab(newIndex);
  };

  const postData = {
    name: personalData.name,
    email: personalData.email,
    phone: personalData.phone,
    building_id: buildingType,
    steep_id: steepType,

    current_material_id: currentMaterialId,
    current_brand_id: currentBrandId,
    current_design_id: currentDesignId,

    choosen_material_id: desiredMaterialId,
    choosen_brand_id: desiredBrandId,
    choosen_design_id: desiredDesignId,
    total_cost: totalPrice,
    working_plan_id: workPlan,
    finance: projectFinancing,
    project_description: projectDescription,
    lat: mapCenter.lat,
    lng: mapCenter.lng,
  };

  console.log(postData);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);

    try {
      const response = await axios.post(
        "https://be.stormsaverroofing.mohamedradwan.me/api/order",
        postData
      );
      console.log("Response:", response.data);
      toast.success("Order Created Successfully !");
      setTimeout(() => {
        window.location.reload();
      }, 2500);
    } catch (error) {
      setErrors(error.response.data.errors);
      console.error("Error:", error.response.data.errors);
    }
  };
  console.log(errors);

  const renderErrors = () => {
    if (formSubmitted) {
      return Object.keys(errors).map((field) => {
        errors[field].forEach((errorMessage, index) => {
          // Display error toast for each error message
          toast.error(errorMessage, { id: `${field}-${index}` });
          setFormSubmitted(false);
          setErrors({});
        });

        // Render an empty div to satisfy React requirements for mapping
        return <div key={field}></div>;
      });
    }

  };

  return (
    <div>
      <Tabs
        id="multi-step-tabs"
        activeKey={key}
        onSelect={(k) => {
          setKey(k);
          setSelectedTab(Number(k.slice(-1)) - 1);
        }}
        className="mt-3"
        selectedIndex={selectedTab}
        style={{ marginBottom: "1rem" }}
      >
        <Tab eventKey="step1" title="Building">
          <Step1Content setBuildingType={setBuildingType} />
        </Tab>
        <Tab eventKey="step2" title="Steep">
          <Step2Content setSteepType={setSteepType} />
        </Tab>
        <Tab eventKey="step3" title="Current Roof">
          <Step3Content
            setCurrentMaterialId={setCurrentMaterialId}
            setCurrentBrandId={setCurrentBrandId}
            setCurrentTypeId={setCurrentTypeId}
            setCurrentDesignId={setCurrentDesignId}
          />
        </Tab>
        <Tab eventKey="step4" title="Desired Roof">
          <Step4Content
            setDesiredMaterialId={setDesiredMaterialId}
            setDesiredBrandId={setDesiredBrandId}
            setDesiredTypeId={setDesiredTypeId}
            setDesiredDesignId={setDesiredDesignId}
            setDesiredDesignPrice={setDesiredDesignPrice}
          />
        </Tab>
        <Tab eventKey="step5" title="Estimated Date">
          <Step5Content setWorkPlan={setWorkPlan} />
        </Tab>
        <Tab eventKey="step6" title="Description">
          <Step6Content setProjectDescription={setProjectDescription} />
        </Tab>
        <Tab eventKey="step7" title="Financing">
          <Step7Content setProjectFinancing={setProjectFinancing} />
        </Tab>
        <Tab eventKey="step8" title="Map">
          <Step8Content
            setMapCenter={setMapCenter}
            setDesiredArea={setDesiredArea}
            desiredDesignPrice={desiredDesignPrice}
            setTotalPrice={setTotalPrice}
          />
        </Tab>
        <Tab eventKey="step9" title="Estimate Form">
          <Step9Content setPersonalData={setPersonalData} />
        </Tab>
      </Tabs>

      <div className="rn-form-group">
        {selectedTab > 0 && (
          <button
            className="rn-button-style--2 btn-solid"
            onClick={handleBack}
            style={{ marginRight: "1rem" }}
            disabled={selectedTab === 0}
          >
            Previous
          </button>
        )}
        {selectedTab < 8 ? (
          <button
            className="rn-button-style--2 btn-solid"
            onClick={handleNext}
            style={{ marginTop: "2rem" }}
            disabled={selectedTab === tabCount - 1}
          >
            Next
          </button>
        ) : (
          <button
            className="rn-button-style--2 btn-solid"
            onClick={handleSubmit}
            style={{ marginTop: "2rem" }}
          >
            Submit Now
          </button>
        )}
        <div>
          <Toaster position="top-right" reverseOrder={false} />
          {renderErrors()}
        </div>
      </div>
    </div>
  );
};

export default MultiStepTabs;
