// DollarRating.js
import React from "react";
import PropTypes from "prop-types";
import styles from "./DollarRating.module.css";

const DollarRating = ({ rating }) => {
  const defaultRating = 5; // Assuming a default of 5 dollar signs
  const dollarSigns = Array.from({ length: defaultRating }, (_, index) => (
    <span key={index} style={{ color: index < rating ? "green" : "black" }}>
      $
    </span>
  ));

  return (
    <div className={styles["dollar-rating"]}>
      <div>{dollarSigns}</div>
    </div>
  );
};

DollarRating.propTypes = {
  rating: PropTypes.number.isRequired,
};

export default DollarRating;
