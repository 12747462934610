import React, { useState, useEffect } from "react";
import useFetch from "../../../../hooks/useFetch";
import Card from "../Card";
import styles from "../building/Building.module.css";

const EstimatedDate = ({ setWorkPlan }) => {
  const [date, setDate] = useState([]);
  const [dateId, setDateId] = useState("");
  const [showBaseUrl , setShowBaseUrl] = useState(true);

  const { data: EstimatedDate } =
    useFetch("working-plan");

  useEffect(() => {
    const loadedDate = [];

    if (EstimatedDate ) {
      for (const key in EstimatedDate) {
        loadedDate.push({
          id: EstimatedDate[key].id,
          type: EstimatedDate[key].content,
        });
      }
      setDate(loadedDate);
      console.log(date);
    } else {
      console.log("No Dates available");
    }
  }, [EstimatedDate]);

  const handleCardClick = (id) => {
    setDateId(id);
    setWorkPlan(id);
    setShowBaseUrl(true);
  };

  const Date = date.map((m) => (
    <Card
      key={m.id}
      id={m.id}
      title={m.type}
      image={"/assets/images/icons/time.jpg"}
      isSelected={m.id === dateId}
      showUrl={showBaseUrl}
      onClick={handleCardClick}
    />
  ));
  
  return (
  <div className={styles.container}>
      {Date}
  </div>);
};

export default EstimatedDate;
