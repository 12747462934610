import React from "react";
import PostFooter from "../PostFooter";
import DUMMY_POSTS from "../DUMMY_POSTS";
import styles from "../PostContent.module.css";
import RecentPosts from "../RecentPosts";

const PostFive = () => {
  const fivthPost = DUMMY_POSTS[4];

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.middleContainer}>
          <div className={styles.innerContainer}>
            <div className={styles.creatorInfo}>
              <img src={fivthPost.creator.image} alt="Creator Image" />
              <p>
                <em>{fivthPost.creator.name}</em>
              </p>
              <p>{fivthPost.creator.date}</p>
              <p>{fivthPost.creator.time}</p>
            </div>
            <h3>{fivthPost.header}</h3>
            <img src={fivthPost.postImage} alt="Post Image" />
            <div className={styles.description}>
              <h3>1. Understand Your Insurance Policy:</h3>
              <p>
                Before delving into the process of getting insurance to pay for
                your roof replacement, it is crucial to review and comprehend
                your homeowners' insurance policy. Take note of specific clauses
                related to roof coverage, any exclusions, and requirements you
                need to meet when filing a claim.
              </p>
              <h3>2. Document Pre-existing Roof Conditions:</h3>
              <p>
                Maintaining accurate records is vital when dealing with
                insurance claims. Before initiating the process, document the
                current condition of your roof with photographs or videos. This
                documentation should include any pre-existing damages or signs
                of aging to build a strong case with your insurance company.
              </p>
              <p>
                “Roofing is an essential aspect of architecture, where
                functionality meets design, and craftsmanship blends with
                innovation.” - Frank Lloyd Wright, Renowned Architect
              </p>
              <h3>3. Does Homeowners Insurance Cover Roof Leaks?</h3>
              <p>
                Roof leaks are a common concern for homeowners, and their
                coverage depends on the circumstances surrounding the issue.
                Review your policy to determine if it covers roof leaks caused
                by sudden and accidental damages, such as storm damage or
                falling objects. Promptly address any leaks to mitigate further
                damage and consult with your insurance agent to evaluate if your
                claim is eligible for reimbursement.
              </p>
              <h3>4. Will Insurance Cover a 20-Year-Old Roof?</h3>
              <p>
                The age of your roof can be a factor when seeking insurance
                coverage. Some insurance policies may have limitations on roof
                age, excluding roofs that are 20 years old or older. Review your
                policy to check for any restrictions, and if your roof is
                nearing this age, consider discussing options with your
                insurance agent.
              </p>
              <h3>5. Perform Regular Roof Maintenance</h3>
              <p>
                Proactive roof maintenance can strengthen your case when filing
                an insurance claim. Regularly inspect and maintain your roof,
                keeping records of inspections and repairs. Demonstrating
                responsible homeownership and a well-maintained roof can
                positively impact your insurance claim.
              </p>
              <h3>6. Seek Professional Roof Inspection</h3>
              <p>
                Prior to filing a claim, engage a qualified roofing professional
                to conduct a thorough roof inspection. Their expertise will
                identify any issues that could affect your insurance claim and
                provide an unbiased report to support your case.
              </p>
              <h3>Get Your Roof Fixed!</h3>
              <p>
                Getting insurance to pay for your roof replacement requires
                diligence, understanding your insurance policy, and proactive
                measures to safeguard your home. Documenting pre-existing
                conditions, addressing roof leaks promptly, and considering the
                age of your roof are essential steps. Regular roof maintenance
                and seeking professional inspection will further bolster your
                claim. Remember to work closely with your insurance agent
                throughout the process. By following these guidelines, you can
                increase the chances of receiving the insurance coverage you
                need to ensure a seamless and successful roof replacement.
              </p>
            </div>
            <PostFooter />
          </div>
        </div>
      </div>
      <div className={styles.recentContainer}>
        <RecentPosts />
      </div>
    </div>
  );
};

export default PostFive;
