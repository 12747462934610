import React, { useState, useCallback, useRef, useEffect } from "react";
import { GoogleMap, LoadScript, Polygon } from "@react-google-maps/api";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const GoogleMapsComponent = ({
  center,
  onMapClick,
  polygonCoords,
  getPolygon,
}) => {
  const [path, setPath] = useState([]);
  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState(center);
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);

  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      console.log("nextPath", nextPath);
      setPath(nextPath);
    }
  }, [setPath]);

  useEffect(() => {
    if (polygonCoords && polygonCoords.length > 0) {
      const initialPath = polygonCoords.map(({ lat, lng }) => ({ lat, lng }));
      setPath(initialPath);
    }
  }, [polygonCoords]);

  useEffect(() => {
    console.log("polygonCoords", polygonCoords);
  }, [polygonCoords]);

  const onLoad = useCallback(
    (polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
    },
    [onEdit]
  );

  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      setAddress(selectedAddress);
      setCoordinates(latLng);
      getPolygon(latLng.lat, latLng.lng);
    } catch (error) {
      console.error("Error selecting address", error);
    }
  };

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyBGxdGnyVfi806eyfIwibnKAVFmKimfAjI"
      id="script-loader"
      libraries={["places"]}
    >
      <PlacesAutocomplete
        value={address}
        onChange={(value) => setAddress(value)}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: "Search Places...",
                className: "location-search-input",
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const style = suggestion.active
                  ? { backgroundColor: "#41b6e6", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      style,
                    })}
                  >
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={coordinates}
        zoom={18.5}
        onClick={onMapClick}
        mapTypeId="satellite"
      >
        <Polygon
          path={path}
          editable
          draggable
          onMouseUp={onEdit}
          onDragEnd={onEdit}
          onLoad={onLoad}
          onUnmount={onUnmount}
        />
      </GoogleMap>
    </LoadScript>
  );
};

export default GoogleMapsComponent;
