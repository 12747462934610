import React from "react";
import styles from "./Benefits.module.css";

const Benefits = () => {
  return (
    <div className={styles.container}>
      <div className={styles.topSection}>
        <div className={styles.header}>
          <span>BENEFITS</span>
          <h3>WHY CHOOSE STORM GROUP?</h3>
        </div>
        <div>
          <p>
            For your exterior home renovation work, Storm Saver focuses on
            providing quality and experience. Homeowners in Louisville, Kentucky
            can count on Storm Saver to provide them with top-notch service and
            information on the best roofing products and systems.
          </p>
        </div>
      </div>
      <div className={styles.bottomSection}></div>
      <div className={styles.upperSection}>
        <div className={styles.card}>
          <img src="/assets/images/icons/done.png" />
          <h4>Seasoned in expertise</h4>
          <p>
            Count on our team of experts to complete your project promptly and
            effectively for everything from residential roofing services to
            vinyl siding installation and storm damage restoration!
          </p>
        </div>
        <div className={styles.card}>
          <img src="/assets/images/icons/done.png" />
          <h4> Reliable enterprise</h4>
          <p>
            Our work is careful, orderly, and exact, and we strive for total
            client pleasure. We have earned our reputation via these qualities.
          </p>
        </div>
        <div className={styles.card}>
          <img src="/assets/images/icons/done.png" />
          <h4>Knowledge cultivation</h4>
          <p>
            If you have any concerns about the price of a roof replacement, the
            parts of a roof, how we manage damaged roofs, or even how our repair
            service operates, feel free to contact one of our knowledgeable
            consultants.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
