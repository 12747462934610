import React, { useState, useEffect } from "react";
import LocationMap from "./LocationMap";
import styles from "./Location.module.css";

const Location = () => {
  const [location, setLocation] = useState({
    lat: 38.1390154,
    lng: -85.7655231,
  });

  const address = "116 Danny Ct, Louisville, KY 40214";

  useEffect(() => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=116+Danny+Ct,+Louisville,+KY+40214&key=AIzaSyBGxdGnyVfi806eyfIwibnKAVFmKimfAjI`
    )
      .then((response) => response.json())
      .then((data) => {
        // Extract latitude and longitude from the response
        const { lat, lng } = data.results[0].geometry.location;
        setLocation({ lat, lng });
      })
      .catch((error) => {
        console.error("Error fetching location data", error);
      });
  }, []);

  return (
    <div className={styles.container}>
      <h3>Opening Hours</h3>
      <p>Mon - Fri 8:00 am – 8:00 pm</p>
      <LocationMap
        location={location}
        link={`https://www.google.com/maps?q=${encodeURIComponent(
          address
        )}`}
      />
    </div>
  );
};

export default Location;
