import React, { useState } from "react";

const ProjectDetails = ({ setProjectDescription }) => {
  const [details, setDetails] = useState("");

  const handleInputChange = (e) => {
    const { value } = e.target;
    setDetails(value);
    setProjectDescription(value);
  };

  return (
    <div>
      <h4 style={{marginTop:"2rem" , marginBottom:"1rem"}}>Project Details</h4>
      <div>
        <textarea
          name="details"
          placeholder="Enter Project Details"
          value={details}
          onChange={handleInputChange}
          style={{height:"150px"}}
        ></textarea>
      </div>
    </div>
  );
};

export default ProjectDetails;
