import React from "react";
import styles from "./PostContent.module.css";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { AiOutlineLink } from "react-icons/ai";
import { FcLike } from "react-icons/fc";

const PostFooter = () => {
  return (
    <div>
      <div className={styles.footerIcons}>
        <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.stormsaverroofing.com%2Fpost%2Fhow-the-color-of-shingles-can-impact-your-electric-bill">
          <FaFacebookF />
        </a>
        <a href="https://twitter.com/share?url=https://www.stormsaverroofing.com/post/how-the-color-of-shingles-can-impact-your-electric-bill">
          <FaTwitter />
        </a>
        <a href="https://www.linkedin.com/shareArticle?mini=true&url=https://www.stormsaverroofing.com/post/how-the-color-of-shingles-can-impact-your-electric-bill">
          <FaLinkedinIn />
        </a>
        <a>
          <AiOutlineLink />
        </a>
      </div>
      <div className={styles.footer}>
        <div className={styles.footerRight}>
          <p>150 views</p>
          <p>10 comments</p>
        </div>
        <div>
          <FcLike />
        </div>
      </div>
    </div>
  );
};

export default PostFooter;
