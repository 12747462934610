import React, { useEffect } from "react";
import { FiChevronUp, FiX, FiMenu } from "react-icons/fi";
import { Link } from "react-router-dom";
import Scrollspy from "react-scrollspy";

const Header = () => {
  useEffect(() => {
    const handleScroll = () => {
      const value = window.scrollY;
      const header = document.querySelector(".header--fixed");
      if (value > 50) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const menuTrigger = () => {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  };

  const closeMenuTrigger = () => {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  };

  return (
    <header className="header-area formobile-menu header--fixed default-color">
      <div className="header-wrapper" id="header-wrapper">
        <div className="header-left">
          <div className="logo">
            <a href="/">
              <img
                className="logo-1"
                src="/assets/images/logo/new-logo.png"
                alt="Logo Images"
              />
              <img
                className="logo-2"
                src="/assets/images/logo/new-logo.png"
                alt="Logo Images"
              />
            </a>
          </div>
        </div>
        <div className="header-right">
          <nav className="mainmenunav d-lg-block">
            <Scrollspy
              className="mainmenu"
              items={["home", "bookOnline", "Blog", "Instance"]}
              currentClassName="is-current"
              offset={-200}
            >
              <li>
                <Link to="/storm-roofer">Home</Link>
              </li>
              <li>
                <Link to="/storm-roofer/book-online" href="#service">
                  Book Online
                </Link>
              </li>
              <li>
                <Link to="/storm-roofer/blog" href="#getstart">
                  Blog
                </Link>
              </li>
              <li>
                <Link to="/storm-roofer/instance" href="#about">
                  Instance
                </Link>
              </li>
            </Scrollspy>
          </nav>
          <div className="header-btn">
            <a
              className="rn-btn"
              // href="https://themeforest.net/checkout/from_item/25457315?license=regular"
            >
              <div style={{ display: "flex", gap: "15px" }}>
                <span>Contact Us </span>
                <span> +1 502-977-4935</span>
              </div>
            </a>
          </div>
          {/* Start Humberger Menu  */}
        </div>
      </div>
    </header>
  );
};

export default Header;
