import React, { Component, Fragment } from "react";
import Scrollspy from "react-scrollspy";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp, FiX, FiMenu } from "react-icons/fi";

import Helmet from "../../component/common/Helmet";
import slideBackground from "../../../public/assets/StormRoofer/slideBackground.webp";
import { Link } from "react-router-dom/cjs/react-router-dom";
import MultiStepForm from "../../elements/tab/multiStepForm";

import TabTwo from "../../elements/tab/TabTwo";
import MultiStepTabs from "../../elements/Refactor/MultiStepTabs";
let title = "Best Roofing Company",
  description =
    'There are many variations of passages of Lorem Ipsum available, but the majority have suffered <a href="#">alteration</a> in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum,';

class Instance extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.stickyHeader = this.stickyHeader.bind(this);
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }
  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }
  stickyHeader() {}
  render() {
    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 50) {
        document.querySelector(".header--fixed").classList.add("sticky");
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky");
      }
    });

    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
    return (
      <Fragment>
        <Helmet pageTitle="Storm Roofer" />

        {/* Start Header Area  */}
        <header className="header-area formobile-menu header--fixed default-color">
          <div className="header-wrapper" id="header-wrapper">
            <div className="header-left">
              <div className="logo">
                <a href="/">
                  <img
                    className="logo-1"
                    src="/assets/images/logo/new-logo.png"
                    alt="Logo Images"
                  />
                  <img
                    className="logo-2"
                    src="/assets/images/logo/new-logo.png"
                    alt="Logo Images"
                  />
                </a>
              </div>
            </div>
            <div className="header-right">
              <nav className="mainmenunav d-lg-block">
                <Scrollspy
                  className="mainmenu"
                  items={["home", "bookOnline", "Blog", "Instance"]}
                  currentClassName="is-current"
                  offset={-200}
                >
                  <li>
                    <Link to="/storm-roofer">Home</Link>
                  </li>
                  <li>
                    <Link to="/storm-roofer/book-online" href="#service">
                      Book Online
                    </Link>
                  </li>
                  <li>
                    <Link to="/storm-roofer/blog" href="#getstart">
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link to="/storm-roofer/instance" href="#about">
                      Instance
                    </Link>
                  </li>
                </Scrollspy>
              </nav>
              <div className="header-btn">
                <a
                  className="rn-btn"
                  // href="https://themeforest.net/checkout/from_item/25457315?license=regular"
                >
                  <div style={{display:"flex" , gap:"15px"}}>
                    <span>Contact Us </span>
                    <span> +1 502-977-4935</span>
                  </div>
                </a>
              </div>
              {/* Start Humberger Menu  */}
              <div className="humberger-menu d-block d-lg-none pl--20">
                <span
                  onClick={this.menuTrigger}
                  className="menutrigger text-white"
                >
                  <FiMenu />
                </span>
              </div>
              {/* End Humberger Menu  */}
              <div className="close-menu d-block d-lg-none">
                <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                  <FiX />
                </span>
              </div>
            </div>
          </div>
        </header>
        {/* End Header Area  */}

        {/* Start Slider Area   */}
        <div className="slider-activation slider-creative-agency">
          <div
            className="bg_image"
            style={{
              backgroundImage: `url(${slideBackground})`,
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            data-black-overlay="4"
          ></div>
        </div>
        {/* End Slider Area   */}

        {/* Start Form Area */}
        <div id="about" className="fix">
          <div className="about-area ptb--120  bg_color--1">
            <div className="about-wrapper">
              <div className="container">
                <div className="row row--35 align-items-center">
                  <div className="col-lg-12 text-center">
                    <div className="about-inner inner">
                      <div className="section-title">
                        <h2 className="title">{title}</h2>
                        <p className="description">{description}</p>
                      </div>
                      <div className="mt--30 col-lg-12">
                        <MultiStepTabs />
                      </div> 
                      {/* <div className="mt--30 col-lg-12">
                        <TabTwo tabStyle="tab-style--1" />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Form Area */}

        

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}

export default Instance;
