import React, { Component, Fragment } from "react";
import Scrollspy from "react-scrollspy";
import ScrollToTop from "react-scroll-up";
import styles from "./BookOnline.module.css";
import {
  FiChevronUp,
  FiX,
  FiMenu,
  FiCast,
  FiLayers,
  FiUsers,
  FiMonitor,
} from "react-icons/fi";

import Helmet from "../../component/common/Helmet";
import ContactTwo from "../../elements/contact/ContactTwo";
import AboutTwo from "../../component/HomeLayout/homeOne/AboutTwo";
import slideBackground from "../../../public/assets/StormRoofer/slideBackground.webp";

import Service1 from "../../../public/assets/StormRoofer/Service1.webp";
import Service2 from "../../../public/assets/StormRoofer/Service2.webp";
import Service3 from "../../../public/assets/StormRoofer/Service3.webp";
import Service4 from "../../../public/assets/StormRoofer/Service4.webp";
import Service5 from "../../../public/assets/StormRoofer/Service5.webp";
import Service6 from "../../../public/assets/StormRoofer/Service6.webp";
import ModalVideo from "react-modal-video";
import { Link } from "react-router-dom/cjs/react-router-dom";

const SlideList = [
  {
    textPosition: "text-center",
    category: "",
    title:
      "Building a Strong Roof for You, Because Your Shelter Deserves No Compromises",
    description:
      "Providing resilient roofing solutions to protect you from nature’s fury and ensure lasting security.",
    buttonText: "Start Now",
    buttonLink: "/storm-roofer",
  },
];
const ServiceList = [
  {
    icon: <FiCast />,
    background: Service1,
    title: "Roof Installation",
    description:
      "We offer professional roof installation services to provide our clients with a sturdy and durable roof that perfectly fits their needs. Our team of skilled technicians ensures a flawless installation process, using high-quality materials and advanced techniques to deliver exceptional results.",
  },
  {
    icon: <FiLayers />,
    background: Service2,
    title: "Roof Repair",
    description:
      "We specialize in efficient and reliable roof repair services. Our experienced technicians thoroughly evaluate the extent of damage and promptly address any issues such as leaks, missing shingles, or damaged flashing. With our expertise, we strive to restore the integrity of your roof and ensure long-lasting protection.",
  },
];

class BookOnline extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.stickyHeader = this.stickyHeader.bind(this);
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }
  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }
  stickyHeader() {}
  render() {
    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 50) {
        document.querySelector(".header--fixed").classList.add("sticky");
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky");
      }
    });

    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
    return (
      <Fragment>
        <Helmet pageTitle="Storm Roofer" />

        {/* Start Header Area  */}
        <header className="header-area formobile-menu header--fixed default-color">
          <div className="header-wrapper" id="header-wrapper">
            <div className="header-left">
              <div className="logo">
                <a href="/">
                  <img
                    className="logo-1"
                    src="/assets/images/logo/new-logo.png"
                    alt="Logo Images"
                  />
                  <img
                    className="logo-2"
                    src="/assets/images/logo/new-logo.png"
                    alt="Logo Images"
                  />
                </a>
              </div>
            </div>
            <div className="header-right">
              <nav className="mainmenunav d-lg-block">
                <Scrollspy
                  className="mainmenu"
                  items={["home", "bookOnline", "Blog", "Instance"]}
                  currentClassName="is-current"
                  offset={-200}
                >
                  <li>
                    <Link to="/storm-roofer">Home</Link>
                  </li>
                  <li>
                    <Link to="/storm-roofer/book-online" href="#service">
                      Book Online
                    </Link>
                  </li>
                  <li>
                    <Link to="/storm-roofer/blog" href="#getstart">
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link to="/storm-roofer/instance" href="#about">
                      Instance
                    </Link>
                  </li>
                </Scrollspy>
              </nav>
              <div className="header-btn">
                <a
                  className="rn-btn"
                  // href="https://themeforest.net/checkout/from_item/25457315?license=regular"
                >
                  <div style={{ display: "flex", gap: "15px" }}>
                    <span>Contact Us </span>
                    <span> +1 502-977-4935</span>
                  </div>
                </a>
              </div>
              {/* Start Humberger Menu  */}
              <div className="humberger-menu d-block d-lg-none pl--20">
                <span
                  onClick={this.menuTrigger}
                  className="menutrigger text-white"
                >
                  <FiMenu />
                </span>
              </div>
              {/* End Humberger Menu  */}
              <div className="close-menu d-block d-lg-none">
                <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                  <FiX />
                </span>
              </div>
            </div>
          </div>
        </header>
        {/* End Header Area  */}

        {/* Start Slider Area   */}
        <div className="slider-activation slider-creative-agency">
          <div
            className="bg_image"
            style={{ backgroundImage: `url(${slideBackground})` }}
            data-black-overlay="4"
          >
            {SlideList.map((value, index) => (
              <div
                className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center"
                key={index}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className={`inner ${value.textPosition}`}>
                        {value.category ? <span>{value.category}</span> : ""}
                        {value.title ? (
                          <h2 className="title theme-gradient">
                            {value.title}
                          </h2>
                        ) : (
                          ""
                        )}
                        {value.description ? (
                          <p className="description">{value.description}</p>
                        ) : (
                          ""
                        )}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 100,
                          }}
                        >
                          {value.buttonText ? (
                            <div className="slide-btn">
                              <a
                                className="rn-button-style--2 btn-primary-color"
                                href={`${value.buttonLink}`}
                              >
                                {value.buttonText}
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* End Slider Area   */}

        {/* Start Service Area */}
        <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--30">
                  <h2>Our Services</h2>
                </div>
              </div>
            </div>
            <div className={styles.bookSection}>
              <div className={styles.innerContainer}>
                <div className={styles.smallContainer}>
                  <img
                    src="/assets/images/booking/book-now-icon.png"
                    alt="book now"
                  />
                  <h4>Home Visit For Free Roofing Estimate</h4>
                  <span>1 hr</span>
                </div>
                <div>
                  <button>
                    <span>Book Now</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}

export default BookOnline;
