import React, { useState, useEffect } from "react";
import useFetch from "../../../../hooks/useFetch";
import Card from "../Card";
import styles from "./Building.module.css";

const Building = ({ setBuildingType }) => {
  const [building, setBuilding] = useState([]);
  const [buildingId, setBuildingId] = useState("");

  const { data: BuildingData, isLoading: BuildingIsLoading } =
    useFetch("building");

  useEffect(() => {
    const loadedProducts = [];

    if (BuildingData && !BuildingIsLoading) {
      for (const key in BuildingData) {
        loadedProducts.push({
          id: BuildingData[key].id,
          image: BuildingData[key].images[0].url,
          type: BuildingData[key].type,
        });
      }
      setBuilding(loadedProducts);
      console.log(building);
    } else {
      console.log("No products available");
    }
  }, [BuildingData, BuildingIsLoading]);

  const handleCardClick = (id) => {
    setBuildingId(id);
    setBuildingType(id);
  };

  const test = building.map((m) => (
    <Card
      key={m.id}
      id={m.id}
      title={m.type}
      image={m.image}
      isSelected={m.id === buildingId}
      onClick={handleCardClick}
    />
  ));
  
  return (
  <div className={styles.container}>
      {test}
  </div>);
};

export default Building;
