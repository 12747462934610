import React, { Component, Fragment, useEffect } from "react";
import Scrollspy from "react-scrollspy";
import ScrollToTop from "react-scroll-up";
import {
  FiChevronUp,
  FiX,
  FiMenu,
  FiCast,
  FiLayers,
  FiUsers,
  FiMonitor,
} from "react-icons/fi";

import Helmet from "../../component/common/Helmet";
import ContactTwo from "../../elements/contact/ContactTwo";
import AboutTwo from "../../component/HomeLayout/homeOne/AboutTwo";
import slideBackground from "../../../public/assets/StormRoofer/slideBackground.webp";

import ModalVideo from "react-modal-video";
import { Link } from "react-router-dom/cjs/react-router-dom";
import DemoCarousel from "./components/Carousel";
import AboutFour from "../../component/HomeLayout/homeOne/Aboutfour";
import AboutThree from "../../component/HomeLayout/homeOne/AboutThree";
import Benefits from "../../component/HomeLayout/homeOne/Benefits";
import YouTubeVideo from "./learning/YoutubeVideo";
import ServiceList from "./ServiceList";
import RoofDesign from "../../component/HomeLayout/designs/RoofDesign";
import HomeCarousel from "./HomeCarousel";
import Location from "./location/Location";

const SlideList = [
  {
    textPosition: "text-center",
    category: "",
    heading: "Saving Homes, Protecting Families: Storm Saver Roofing",
    title: "EXCELLENCE IN ROOFING, KENTUCKY’S BEST ROOFING COMPANY.",
    description:
      "All of your home's exterior needs are taken care of by Storm Saver. Our skilled team is available to assist you whether you need new siding, gutters, or a roof replacement.",
    buttonText: "Contact Us",
    buttonPhone: "+1 502-977-4935",
    buttonLink: "/contact",
  },
];

class StormRoofer extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.stickyHeader = this.stickyHeader.bind(this);
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }
  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }
  stickyHeader() {}
  render() {
    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 50) {
        document.querySelector(".header--fixed").classList.add("sticky");
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky");
      }
    });

    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }

    return (
      <Fragment>
        <Helmet pageTitle="Storm Roofer" />

        {/* Start Header Area  */}
        <header className="header-area formobile-menu header--fixed default-color">
          <div className="header-wrapper" id="header-wrapper">
            <div className="header-left">
              <div className="logo">
                <a href="/">
                  <img
                    className="logo-1"
                    src="/assets/images/logo/new-logo.png"
                    alt="Logo Images"
                  />
                  <img
                    className="logo-2"
                    src="/assets/images/logo/new-logo.png"
                    alt="Logo Images"
                  />
                </a>
              </div>
            </div>
            <div className="header-right">
              <nav className="mainmenunav d-lg-block">
                <Scrollspy
                  className="mainmenu"
                  items={["home", "bookOnline", "Blog", "Instance"]}
                  currentClassName="is-current"
                  offset={-200}
                >
                  <li>
                    <Link to="/storm-roofer">Home</Link>
                  </li>
                  <li>
                    <Link to="/storm-roofer/book-online" href="#service">
                      Book Online
                    </Link>
                  </li>
                  <li>
                    <Link to="/storm-roofer/blog" href="#getstart">
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link to="/storm-roofer/instance" href="#about">
                      Instance
                    </Link>
                  </li>
                </Scrollspy>
              </nav>
              <div className="header-btn">
                <a
                  className="rn-btn"
                  // href="https://themeforest.net/checkout/from_item/25457315?license=regular"
                >
                  <div style={{ display: "flex", gap: "15px" }}>
                    <span>Contact Us </span>
                    <span> +1 502-977-4935</span>
                  </div>
                </a>
              </div>
              {/* Start Humberger Menu  */}
              <div className="humberger-menu d-block d-lg-none pl--20">
                <span
                  onClick={this.menuTrigger}
                  className="menutrigger text-white"
                >
                  <FiMenu />
                </span>
              </div>
              {/* End Humberger Menu  */}
              <div className="close-menu d-block d-lg-none">
                <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                  <FiX />
                </span>
              </div>
            </div>
          </div>
        </header>
        {/* End Header Area  */}

        {/* Start Slider Area   */}
        <div className="slider-activation slider-creative-agency">
          <div
            className="bg_image"
            style={{ backgroundImage: `url(${slideBackground})` }}
            data-black-overlay="4"
          >
            {SlideList.map((value, index) => (
              <div
                className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center"
                key={index}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className={`inner ${value.textPosition}`}>
                        {value.category ? <span>{value.category}</span> : ""}
                        {value.heading ? (
                          <h4 style={{ color: "white" }}>{value.heading}</h4>
                        ) : (
                          ""
                        )}
                        {value.title ? (
                          <h2 className="title theme-gradient">
                            {value.title}
                          </h2>
                        ) : (
                          ""
                        )}
                        {value.description ? (
                          <p className="description">{value.description}</p>
                        ) : (
                          ""
                        )}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 100,
                          }}
                        >
                          {value.buttonText ? (
                            <div className="slide-btn ">
                              <a
                                className="rn-button-style--2 btn-primary-color"
                                href={"/"}
                              >
                                {value.buttonText}
                                {value.buttonPhone}
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="slide-btn">
                            <a
                              href="#our-services"
                              className="rn-button-style--2 btn-primary-color"
                              // style={{scrollBehavior:"smooth"}}
                            >
                              Services
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* End Slider Area   */}

        {/* Start Contact Area */}
        <div style={{ paddingTop: 100, paddingBottom: 100 }}>
          <ContactTwo />
        </div>
        {/* End Contact Area */}

        {/* Start Carousel Area */}


        {/* End Carousel Area */}

        {/* Start Gallery Box  */}
        <RoofDesign />

        {/* End Gallery Box  */}

        {/* Start Service Area */}
        <div
          id="our-services"
          className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1"
        >
          <div
            className="container"
            style={{ borderTop: "1px solid", paddingTop: "3rem" }}
          >
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--30">
                  <h2>Our Services</h2>
                </div>
              </div>
            </div>
            <div className="row creative-service">
              {ServiceList.map((val, i) => (
                <div
                  id={val.id}
                  className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"
                  key={i}
                >
                  {/* <a className="text-center" href="/service-details"></a> */}
                  <div
                    className="service service__style--2"
                    style={{
                      backgroundImage: `url(${val.background})`,
                      backgroundSize: "cover",
                    }}
                  >
                    {/* <div className="icon">{val.icon}</div> */}
                    <div className="content"
                    style={{height:"200px"}}
                    >
                      <h3
                        className="title"
                        style={{
                          color: "white",
                        }}
                      >
                        {val.title}
                      </h3>
                      <p
                        style={{
                          color: "white",
                          // lineHeight:"normal",
                          // fontSize:"medium"
                        }}
                      >
                        {val.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Service Area */}
        <Benefits />
        {/* Start Why Choose Us */}
        <AboutTwo />

        <HomeCarousel />
        {/* End Why Choose Us */}
        <AboutThree />
        <AboutFour />
        {/* Start Content Box  */}

        {/* End Content Box  */}

        {/* Start Videos Area  */}
        <div className="service-area creative-service-wrapper ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="feature-area">
                  <h3 className="title" style={{ color: "#FF4040" }}>
                    LEARNING CENTER
                  </h3>
                  <h5 className="title mt--20 fontWeight500 lineheight--1-8">
                    Check out some introductory movies regarding your roof
                    system from our roofing contractors whether you're a new
                    homeowner or just new to roofing.
                  </h5>
                </div>
              </div>
            </div>
            <div className="rn-section pb--120 pt--60 bg_color--1">
              <div className="container">
                <div className="row sercice-details-content align-items-center">
                  <div className="col-lg-4">
                    <div className="thumb position-relative">
                      <YouTubeVideo videoId="kufP0uZHMRY" />
                      {/* <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId="kufP0uZHMRY"
                        onClose={() =>
                          this.setState({
                            isOpen: false,
                          })
                        }
                      /> */}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="thumb position-relative">
                      <YouTubeVideo videoId="5_fwLe3_KHY" />
                      {/* <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId="5_fwLe3_KHY"
                        onClose={() =>
                          this.setState({
                            isOpen: false,
                          })
                        }
                      /> */}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="thumb position-relative">
                      <YouTubeVideo videoId="Rgyz0B8q9Qc" />
                      {/* <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId="Rgyz0B8q9Qc"
                        onClose={() =>
                          this.setState({
                            isOpen: false,
                          })
                        }
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Videos Area  */}
        <Location />

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}

export default StormRoofer;
