import React from "react";
import styles from "./AboutFour.module.css";

const AboutFour = () => {
  return (
    <div className={styles.container}>
      <div className={styles.leftSection}>
        <h4>STORM SAVER: Free Roofing Estimates Within 24 Hours</h4>
        <p>
          At Storm Saver, we understand the importance of timely and accurate
          estimates when it comes to your roofing needs. That's why we are proud
          to offer free roofing estimates, guaranteed to be delivered within 24
          hours.
        </p>
        <p>
          Why Choose <em>Storm Saver</em> for Your Free Estimate?
        </p>
        <div>
          <h5> Tailored to Your House</h5>
          <p>
            We recognize that every house is unique, and pricing can vary
            accordingly. Our team of roofing experts takes into account the
            specific characteristics of your house, such as size, complexity,
            and materials required. This ensures that your estimate is
            customized to meet your specific roofing requirements.
          </p>
        </div>
        <div>
          <h5>Transparent Pricing</h5>
          <p>
            We believe in transparency and ensuring that our customers have a
            clear understanding of the pricing. Our free estimates provide a
            breakdown of the costs involved, so you know exactly what to expect.
            There are no hidden fees or surprises along the way.
          </p>
        </div>
      </div>
      <div className={styles.rightSection}>
        <div className={styles.content}>
          <h3>GET IN TOUCH WITH US TODAY FOR A FREE APPOINTMENT.</h3>
          <p>
            By installing a solid roof over your house, we can help you create a
            safe and secure home.
          </p>
          <button>502-977-4935</button>
        </div>
      </div>
    </div>
  );
};

export default AboutFour;
