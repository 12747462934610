import React, { useState } from "react";
import GoogleMaps from "./GoogleMaps";
import axios from "axios";

const Map = ({
  setMapCenter,
  setDesiredArea,
  setTotalPrice,
  desiredDesignPrice,
}) => {
  const [area, setArea] = useState("");
  const [price, setPrice] = useState("");
  const [coords, setCoords] = useState([]);
  const [center, setcenter] = useState({
    lat: 40.73061,
    lng: -74.935242,
  });
  const handleMapClick = async (event) => {
    const newCoord = { lat: event.latLng.lat(), lng: event.latLng.lng() };
    setCoords([...coords, newCoord]);
  };

  const getPolygon = async (lat, lng) => {
    try {
      const response = await axios.post(
        "https://be.stormsaverroofing.mohamedradwan.me/api/address-polygon",
        {
          lat: lat,
          lng: lng,
        }
      );

      const cooords = JSON.parse(
        response.data?.data[0]?.geojson
      ).coordinates[0].map((c) => {
        return { lat: c[1], lng: c[0] };
      });

      const area = Math.floor(response.data.data[0].sqft);
      setArea(area);
      setDesiredArea(area);
      setTotalPrice(area * desiredDesignPrice);
      setPrice(area * desiredDesignPrice);
      setMapCenter(center);
      console.log("cooooords", cooords);
      console.log(area);
      setCoords(cooords);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <h3 className="title" style={{ lineHeight: 1.3 }}>
            Google Map With Address Input
          </h3>
        </div>
      </div>
      {area && (
        <h3 className="title" style={{ lineHeight: 1.3 }}>
          <p>Your Home Area: {area}</p>
          <p>Your Total Cost: {price} $</p>
        </h3>
      )}

      <GoogleMaps
        center={center}
        onMapClick={handleMapClick}
        polygonCoords={coords}
        mapTypeId="satellite"
        getPolygon={getPolygon}
      />
    </div>
  );
};

export default Map;
