import React from "react";
import PostFooter from "../PostFooter";
import DUMMY_POSTS from "../DUMMY_POSTS";
import styles from "../PostContent.module.css";
import RecentPosts from "../RecentPosts";

const PostTwo = () => {
  const secondPost = DUMMY_POSTS[1];
  return (
    <div>
      <div className={styles.container}>
        <div className={styles.middleContainer}>
          <div className={styles.innerContainer}>
            <div className={styles.creatorInfo}>
              <img src={secondPost.creator.image} alt="Creator Image" />
              <p>
                <em>{secondPost.creator.name}</em>
              </p>
              <p>{secondPost.creator.date}</p>
              <p>{secondPost.creator.time}</p>
            </div>
            <h3>{secondPost.header}</h3>
            <img src={secondPost.postImage} alt="Post Image" />
            <div className={styles.description}>
              <p>
                As a homeowner, it's natural to want to save money and take on
                home improvement projects yourself. However, when it comes to
                gutter maintenance, it's best to leave it to the professionals.
                Hiring a roofing company to handle your gutter maintenance not
                only saves you time and effort, but it also ensures that the job
                is done right. Here are the top reasons why you should skip the
                DIY approach and choose a roofing company for gutter
                maintenance:
              </p>
              <p>
                <em>1. Safety First</em>
              </p>
              <p>
                Working on gutters can be dangerous, especially if you don't
                have the proper equipment or experience. Climbing ladders and
                navigating rooftop areas can lead to accidents and injuries.
                Professional roofing companies have trained technicians who are
                equipped with safety gear and are experienced in working at
                heights. By hiring a roofing company, you eliminate the risk of
                personal injury and ensure the safety of your home and yourself.
              </p>
              <p>
                <em>2. Expertise and Experience</em>
              </p>
              <p>
                Roofing companies specialize in all aspects of roof maintenance,
                including gutter repair and cleaning. They have the knowledge
                and experience to identify any underlying issues that may be
                causing problems with your gutters. Additionally, they can offer
                tailored solutions to ensure that your gutters are functioning
                properly and effectively. A roofing company can also provide
                additional services such as gutter installation and gutter guard
                installation to prevent future issues. With their expertise in
                the field, you can rest assured that your gutter maintenance
                needs are being handled by professionals.
              </p>
              <p>
                <em>3. Time and Effort Savings</em>
              </p>
              <p>
                Gutter maintenance is a time-consuming task that requires
                attention to detail. It involves climbing ladders, removing
                debris, and thoroughly cleaning the gutters. By hiring a roofing
                company, you can save yourself the hassle and time-consuming
                process of doing it yourself.
              </p>
              <p>
                Roofing companies have the necessary tools, equipment, and
                manpower to efficiently clean and maintain your gutters. They
                can complete the job in a fraction of the time it would take you
                to do it yourself, allowing you to focus on other important
                responsibilities.
              </p>
              <p>
                <em>4. Long-Term Cost Savings</em>
              </p>
              <p>
                While it may seem like a cost-effective solution to do gutter
                maintenance yourself, it can actually end up costing you more in
                the long run. If the job is not done properly or if underlying
                issues are not addressed, it can lead to costly repairs or
                replacements down the line.
              </p>
              <p>
                By hiring a roofing company for gutter maintenance, you ensure
                that your gutters are being properly maintained and any
                potential issues are addressed promptly. This helps extend the
                lifespan of your gutters and prevent more significant problems
                from developing. In the long run, this can save you money by
                avoiding costly repairs or replacements.
              </p>
              <p>
                <em>5. Warranty and Insurance Coverage</em>
              </p>
              <p>
                Most reputable roofing companies offer warranties on their
                workmanship and products used. This means that if any issues
                arise after the gutter maintenance service, the company will
                take care of it at no additional cost to you. Additionally,
                professional roofing companies carry insurance coverage, which
                protects you from any liability in case of accidents or property
                damage during the maintenance process.
              </p>
              <p>
                <em>6. Comprehensive Services</em>
              </p>
              <p>
                When you hire a roofing company for gutter maintenance, you can
                expect a comprehensive range of services. In addition to
                cleaning and repairing gutters, they can also inspect your roof
                for any issues, check for leaks, and make necessary repairs.
                This ensures that your entire roofing system is in good
                condition and functioning properly.
              </p>
              <p>
                When it comes to maintaining your gutters, the benefits of
                hiring a roofing company far outweigh the DIY approach. From
                safety to expertise and time savings, a professional roofing
                company can provide efficient and effective gutter maintenance
                that keeps your home protected. Don't risk personal injury or
                inadequate maintenance. Choose a roofing company that
                specializes in gutter maintenance and enjoy the peace of mind
                that comes with knowing your home is in good hands. By investing
                in professional gutter maintenance, you can ensure the longevity
                and functionality of your gutters, ultimately saving you time,
                effort, and money in the long run.
              </p>
            </div>
            <PostFooter />
          </div>
        </div>
      </div>
      <div className={styles.recentContainer}>
        <RecentPosts />
      </div>
    </div>
  );
};

export default PostTwo;
