import React, { useEffect, useState } from "react";
import useFetch from "../../../../hooks/useFetch";
import Card from "../Card";
import styles from "../building/Building.module.css";
import Designs from "./Designs";

const Types = ({
  materialId,
  brandId,
  setDesiredTypeId,
  setDesiredDesignId,
  setDesiredDesignPrice,
}) => {
  const [typeData, setTypeData] = useState([]);
  const [typeId, setTypeId] = useState("");

  const [showDesigns, setShowDesigns] = useState(false);

  console.log(brandId);

  const { data: TypeData } = useFetch(`type?brands=${brandId}`);
  console.log(TypeData);

  useEffect(() => {
    const loadedTypes = [];

    if (TypeData) {
      for (const key in TypeData) {
        loadedTypes.push({
          id: TypeData[key].id,
          image: TypeData[key].image[0].url,
          type: TypeData[key].title,
          rate: TypeData[key].rate,
        });
      }
      setTypeData(loadedTypes);
    } else {
      console.log("No Types available");
    }
  }, [TypeData]);
  console.log(typeData);

  const handleCardClick = (id) => {
    setTypeId(id);
    setDesiredTypeId(id);
    setShowDesigns(true);
  };

  const typeCards = typeData.map((type) => (
    <Card
      key={type.id}
      id={type.id}
      title={type.type}
      image={type.image}
      rate={type.rate}
      isSelected={type.id === typeId}
      showDollar={!showDesigns}
      onClick={() => handleCardClick(type.id)}
    />
  ));

  return (
    <div className={styles.container}>
      {showDesigns ? (
        <Designs
          materialId={materialId}
          brandId={brandId}
          typeId={typeId}
          setDesiredDesignId={setDesiredDesignId}
          setDesiredDesignPrice={setDesiredDesignPrice}
        />
      ) : (
        typeCards
      )}
    </div>
  );
};

export default Types;
