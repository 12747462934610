import React, { useState, useEffect, createRef } from "react";
import useFetch from "../../../hooks/useFetch";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import GoogleMapsComponent from "./GoogleMapsComponent";
import { FiLayers } from "react-icons/fi";
import ResidentialImage from "../../../public/assets/images/StormRoof/house_5687455.png";
import SteepRoof from "../../../public/assets/images/StormRoof/eject_2984609.png";
import YesImage from "../../../public/assets/images/StormRoof/check_1828743.png";
import NoImage from "../../../public/assets/images/StormRoof/close_2976286.png";
import MaybeImage from "../../../public/assets/images/StormRoof/question-mark_57108.png";
import RoofImage from "../../../public/assets/images/StormRoof/Valuing-a-Roofing-Company.png";
import axios from "axios";
import "./custom.css";
import toast, { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import DollarRating from "./dollarRating/DollarRating";
function TabsTwo(props) {
  const tab1 = "Building";
  const tab2 = "Steep";
  const tab3 = "Current Roof";
  const tab4 = "Desired Roof";
  const tab5 = "Estimated Date";
  const tab6 = "Details";
  const tab7 = "Financing";
  const tab8 = "Map View";
  const tab9 = "Form";

  const [BuildingType, setBuildingType] = useState("");
  const [SteepType, setSteepType] = useState("");
  const [CurrentRoof, setCurrentRoof] = useState("");
  const [CurrentRoof2, setCurrentRoof2] = useState("");
  const [Designs, setDesigns] = useState("");
  const [Designs2, setDesigns2] = useState("");
  const [Design, setDesign] = useState("");
  const [Design2, setDesign2] = useState("");
  const [EstimatedDate, setEstimatedDate] = useState("");
  const [Financing, setFinancing] = useState("");
  const [currentProducts, setCurrentProducts] = useState([]);
  const [currentProducts2, setCurrentProducts2] = useState([]);
  const [product, setProduct] = useState("");
  const [allProducts2, setAllProducts2] = useState([]);
  const [product2, setProduct2] = useState("");
  const [brandId, setBrandId] = useState(0);

  const [currentMaterialId, setCurrentMaterialId] = useState("");
  const [currentBrandId, setCurrentBrandId] = useState("");
  const [currentDesignId, setCurrentDesignId] = useState("");

  const [choosenMaterialId, setChoosenMaterialId] = useState("");
  const [choosenBrandId, setChoosenBrandId] = useState("");
  const [choosenDesignId, setChoosenDesignId] = useState("");
  const [designPrice, setDesignPrice] = useState("");
  const [totalPrice, setTotalPrice] = useState("");
  const [types, setTypes] = useState("");
  const [types2, setTypes2] = useState("");
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedBrand2, setSelectedBrand2] = useState(null);
  const [dollarRating , setDollarRating] = useState();
  

  const [Form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    details: "",
  });
  const [materialId, setMaterialId] = useState(0);
  const [designId, setDesignId] = useState(0);
  const [Address, setAddress] = useState("");
  const [coords, setCoords] = useState([]);
  const [area, setArea] = useState("");
  const [mapCenter, setMapCenter] = useState({
    lat: 40.73061,
    lng: -74.935242,
  });
  const [steepId, setSteepId] = useState(0);
  const { data: BuildingData, isLoading: BuildingIsLoading } =
    useFetch("building");
  const { data: SteepData } = useFetch("steep");
  const { data: TypeData } = useFetch("type");

  const { data: EstimatedData } = useFetch("working-plan");
  const {
    data: MaterialData,
    isLoading2,
    error2,
    reFetch: reFetch2,
  } = useFetch(`material`);

  const brandMaterialId = MaterialData.map((MateId) => MateId.id);

  const {
    data: BrandsData,
    isLoading3,
    error3,
    reFetch: reFetch3,
  } = useFetch(`brand?material=${brandMaterialId}`);

  const brandDataId = BrandsData.map((BranId) => BranId.id);
  // Array of fetched data
  const fetchedData = [
    BuildingData,
    SteepData,
    EstimatedData,
    MaterialData,
    BrandsData,
  ];

  console.log(MaterialData);

  const [selectedTab, setSelectedTab] = useState(0);
  const tabCount = 9;
  const {
    data: DesignData,
    isLoading,
    error,
    reFetch,
  } = useFetch(
    `design?material=${materialId}&brand=${brandDataId}&getType=list`
  );
  useEffect(() => {
    reFetch3();
  }, [materialId]);

  useEffect(() => {
    reFetch2();
  }, [steepId]);

  useEffect(() => {
    reFetch();
  }, [brandId]);
  console.log(materialId);
  const baseUrl = "https://be.stormsaverroofing.mohamedradwan.me/";
  const handleMapClick = async (event) => {
    const newCoord = { lat: event.latLng.lat(), lng: event.latLng.lng() };
    setCoords([...coords, newCoord]);
  };
  const getProducts = (id) => {
    setCurrentProducts(BrandsData);
    console.log(currentProducts);
    console.log(BrandsData);
    console.log(currentProducts);
    setProduct(id);
    setMaterialId(id);
  };

  const setCurrentTypes = (id) => {
    setProduct(id);
    setTypes(TypeData);
    console.log("clicked");
    console.log(types);
  };

  const setCurrentTypes2 = (id) => {
    setProduct(id);
    setTypes2(TypeData);
    console.log("clicked");
    console.log(types2);
  };

  const setCurrentProduct = (id) => {
    setProduct(id);
    setDesigns(DesignData);
    console.log(Designs);
  };
  
  const getProducts2 = (id) => {
    setCurrentProducts2(BrandsData);
    console.log(currentProducts2);
    console.log(BrandsData);
    console.log(currentProducts2);
    setProduct(id);
    setMaterialId(id);
  };

  const setCurrentProduct2 = (id) => {
    setProduct2(id);
    setDesigns2(DesignData);
    console.log(Designs);
  };

  const handlePlaceSelected = (place) => {
    if (place && place.formatted_address) {
      // Update the address input field
      setAddress(place.formatted_address);
      // Perform any other actions based on the selected place if needed
    }
  };

  const geocodeAddress = async (address) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: address }, function (results, status) {
      if (status === "OK") {
        console.log("Cords", coords);
        const newCoords = [
          ...coords,
          {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          },
        ];
        // setCoords(newCoords);

        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();
        setMapCenter({ lat: lat, lng: lng });
        getPolygon(lat, lng);
      } else {
        alert("Geocode was not successful for the following reason: " + status);
      }
    });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...Form,
      [name]: value,
    });
  };
  // setTotalPrice(area * designPrice);
  // console.log(totalPrice);

  const postData = {
    name: Form.name,
    email: Form.email,
    phone: Form.phone,
    building_id: BuildingType,
    steep_id: SteepType,

    current_material_id: currentMaterialId,
    current_brand_id: currentBrandId,
    current_design_id: currentDesignId,

    choosen_material_id: choosenMaterialId,
    choosen_brand_id: choosenBrandId,
    choosen_design_id: choosenDesignId,
    total_cost: totalPrice,
    working_plan_id: EstimatedDate,
    finance: Financing,
    project_description: Form.details,
    lat: mapCenter.lat,
    lng: mapCenter.lng,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://be.stormsaverroofing.mohamedradwan.me/api/order",
        postData
      );
      console.log("Response:", response.data);
      toast.success("Order Created Successfully !");
      setTimeout(() => {
        window.location.reload();
      }, 2500);
    } catch (error) {
      const toastError = error.response.data.message;
      console.error("Error:", error);
      toast.error(toastError);
    }
  };

  const getPolygon = async (lat, lng) => {
    try {
      const response = await axios.post(
        "https://be.stormsaverroofing.mohamedradwan.me/api/address-polygon",
        {
          lat: lat,
          lng: lng,
        }
      );

      const cooords = JSON.parse(
        response.data?.data[0]?.geojson
      ).coordinates[0].map((c) => {
        return { lat: c[1], lng: c[0] };
      });

      const area = Math.floor(response.data.data[0].sqft);
      setArea(area);
      setTotalPrice(area * designPrice);
      console.log("cooooords", cooords);
      console.log(area);
      setCoords(cooords);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  console.log("MaterialData:", MaterialData);
  console.log("BrandsData:", BrandsData);
  console.log("types:", types);

  const containerStyle = {
    width: "100%",
    height: "600px",
  };

  console.log(types);
  const resetData = () => {
    setDesigns("");
    setTypes("");
    setTypes2("");
    setDesigns2("");
    setDesign("");
    setDesign2("");
    setCurrentProducts([]);
    setCurrentProducts2([]);
    setProduct("");
    setProduct2("");
    setMaterialId(0);
    setDesignId(0);
    setSteepId(0);
  };

  const handleBack = () => {
    setSelectedTab((selectedTab + tabCount - 1) % tabCount);
  };

  const handleNext = () => {
    setSelectedTab((selectedTab + 1) % tabCount);
  };

  const { tabStyle } = props;
  if (BuildingIsLoading) return "Loading ...";
  return (
    <div>
      {/* Start Tabs Area */}
      <div className="tabs-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <Tabs
                selectedIndex={selectedTab}
                onSelect={(index) => setSelectedTab(index)}
              >
                <TabList className={`${tabStyle} mb-5 pb-5`}>
                  <Tab>{tab1}</Tab>
                  <Tab>{tab2}</Tab>
                  <Tab
                    onClick={() => {
                      resetData();
                      reFetch2();
                    }}
                  >
                    {tab3}
                  </Tab>
                  <Tab
                    onClick={() => {
                      resetData();
                      reFetch2();
                    }}
                  >
                    {tab4}
                  </Tab>
                  <Tab>{tab5}</Tab>
                  <Tab>{tab6}</Tab>
                  <Tab>{tab7}</Tab>
                  <Tab>{tab8}</Tab>
                  <Tab>{tab9}</Tab>
                </TabList>

                {/* Building*/}
                <TabPanel>
                  {BuildingData && (
                    <div className="row">
                      {BuildingData.map((build) => (
                        <div
                          key={build.id}
                          className="col-lg-4 col-md-6 col-sm-6 col-12"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => setBuildingType(build.id)}
                        >
                          <div className="content">
                            <h3 className="title">{build.type}</h3>
                          </div>
                          <div
                            className={
                              "service service__style--2" +
                              (BuildingType === build.id ? " __activeCard" : "")
                            }
                          >
                            <div className="icon">
                              <img
                                src={`${baseUrl}${build.images[0].url}`}
                                alt="Residential"
                                style={{
                                  width: 100,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </TabPanel>

                {/* Steeps */}
                <TabPanel>
                  {
                    <div className="row">
                      {SteepData.map((steep) => (
                        <div
                          key={steep.id}
                          className="col-lg-4 col-md-6 col-sm-6 col-12"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setSteepType(steep.id);
                            setSteepId(steep.id);
                          }}
                        >
                          <div className="content">
                            <h3 className="title">{steep.type}</h3>
                          </div>
                          <div
                            className={
                              "service service__style--2" +
                              (SteepType === steep.id ? "  __activeCard" : "")
                            }
                          >
                            <div className="icon">
                              <img
                                src={`${baseUrl}${steep.images[0].url}`}
                                alt="Commercial"
                                style={{
                                  width: 100,
                                }}
                              />
                            </div>
                            <p>Cannot be walked on</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  }
                </TabPanel>

                {/* Current Roof */}
                <TabPanel>
                  {currentProducts.length === 0 && (
                    // Render MaterialData when currentProducts and Designs are both empty
                    <div className="row">
                      {MaterialData.map((material) => {
                        return (
                          <div
                            key={material.id}
                            className="col-lg-3 col-md-6 col-sm-6 col-12 pb-3"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              getProducts(material.id);
                              setCurrentMaterialId(material.id);
                            }}
                          >
                            <div className="content">
                              <h3 className="title">{material.title}</h3>
                            </div>
                            <div
                              className={
                                "service service__style--2" +
                                (CurrentRoof === material.id
                                  ? "  __activeCard"
                                  : "")
                              }
                              style={{
                                backgroundImage: `url(${baseUrl}${material.images[0].url})`,
                                backgroundSize: "cover",
                              }}
                            ></div>
                          </div>
                        );
                      })}
                    </div>
                  )}

                  {currentProducts.length > 0 && Designs.length === 0 && selectedBrand === null && (
                    <div className="row">
                      {BrandsData.map((material) => {
                        return (
                          <div
                            key={material.id}
                            className="col-lg-3 col-md-6 col-sm-6 col-12 pb-3"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setCurrentTypes(material.id);
                              setCurrentBrandId(material.id);
                              setBrandId(material.id);
                              setSelectedBrand(material.id);
                              
                            }}
                          >
                            <div className="content">
                              <h3 className="title">{material.title}</h3>
                            </div>
                            <div
                              className={"service service__style--2"}
                              style={{
                                backgroundImage: `url(${baseUrl}${material.image[0].url})`,
                                backgroundSize: "cover",
                              }}
                            ></div>
                          </div>
                        );
                      })}
                    </div>
                  )}

                  {currentProducts.length > 0 &&
                    Designs.length === 0 &&
                    types.length > 0 &&
                    selectedBrand !== null && (
                      // Render currentProducts when it's not empty
                      <div>
                        <div className="row">
                          {types.map((type) => {
                            return (
                              <div
                                key={type.id}
                                className="col-lg-3 col-md-6 col-sm-6 col-12 pb-3"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setCurrentProduct(type.id);
                                  setCurrentBrandId(type.id);
                                  
                                }}
                              >
                                <div className="content">
                                  <h3 className="title">{type.title}</h3>
                                </div>
                                <div
                                  className={"service service__style--2"}
                                  style={{
                                    backgroundImage: `url(${baseUrl}${type.image[0].url})`,
                                    backgroundSize: "cover",
                                  }}
                                ></div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    marginTop: "20px",
                                  }}
                                >
                                  <p style={{ margin: "0px" }}>
                                    {type.description}
                                  </p>
                                  <DollarRating rating={type.rate} />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}

                  {currentProducts.length > 0 && Designs.length > 0 && (
                    // Render Designs when currentProducts and Designs are both not empty
                    <div className="row">
                      {Designs.map((design) => {
                        return (
                          <div
                            key={design.id}
                            className="col-lg-3 col-md-6 col-sm-6 col-12 pb-3"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setDesign(design.id, console.log(Design));
                              setCurrentDesignId(design.id);
                            }}
                          >
                            <div className="content">
                              <h3 className="title">{design.name}</h3>
                            </div>
                            <div
                              style={{
                                backgroundImage: `url(${baseUrl}${design.image[0].url})`,
                                backgroundSize: "cover",
                              }}
                              className={
                                "service service__style--2" +
                                (Design === design.id ? " __activeCard" : "")
                              }
                            ></div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </TabPanel>

                {/* Desired Roof */}
                <TabPanel>
                  {currentProducts2.length === 0 && Designs2.length === 0 && (
                    // Render MaterialData when currentProducts2 and Designs2 are both empty
                    <div className="row">
                      {MaterialData.map((material) => {
                        return (
                          <div
                            key={material.id}
                            className="col-lg-3 col-md-6 col-sm-6 col-12 pb-3"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              getProducts2(material.id);
                              setChoosenMaterialId(material.id);
                            }}
                          >
                            <div className="content">
                              <h3 className="title">{material.title}</h3>
                            </div>
                            <div
                              className={
                                "service service__style--2" +
                                (CurrentRoof2 === material.id
                                  ? "  __activeCard"
                                  : "")
                              }
                              style={{
                                backgroundImage: `url(${baseUrl}${material.images[0].url})`,
                                backgroundSize: "cover",
                              }}
                            ></div>
                          </div>
                        );
                      })}
                    </div>
                  )}

                  {currentProducts2.length > 0 && Designs2.length === 0 && selectedBrand2 === null &&(
                    // Render currentProducts2 when it's not empty
                    <div className="row">
                      {currentProducts2.map((material) => {
                        return (
                          <div
                            key={material.id}
                            className="col-lg-3 col-md-6 col-sm-6 col-12 pb-3"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setCurrentTypes2(material.id);
                              setDesignId(material.id);
                              setChoosenBrandId(material.id);
                              setBrandId(material.id);
                              setSelectedBrand2(material.id);
                              reFetch();
                            }}
                          >
                            <div className="content">
                              <h3 className="title">{material.title}</h3>
                            </div>
                            <div
                              className={"service service__style--2"}
                              style={{
                                backgroundImage: `url(${baseUrl}${material.image[0].url})`,
                                backgroundSize: "cover",
                              }}
                            >
                              <div className="icon"></div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}

                  {currentProducts2.length > 0 &&
                    Designs2.length === 0 &&
                    types2.length > 0 &&
                    selectedBrand2 !== null && (
                      // Render currentProducts when it's not empty
                      <div
                        style={{
                          borderTop: ".5px solid ",
                          marginTop: "2rem",
                          paddingTop: "1.5rem",
                        }}
                      >
                        <div className="row">
                          {types2.map((type) => {
                            return (
                              <div
                                key={type.id}
                                className="col-lg-3 col-md-6 col-sm-6 col-12 pb-3"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setCurrentProduct2(type.id);
                                  setCurrentBrandId(type.id);
                                }}
                              >
                                <div className="content">
                                  <h3 className="title">{type.title}</h3>
                                </div>
                                <div
                                  className={"service service__style--2"}
                                  style={{
                                    backgroundImage: `url(${baseUrl}${type.image[0].url})`,
                                    backgroundSize: "cover",
                                  }}
                                ></div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    marginTop: "20px",
                                  }}
                                >
                                  <p style={{ margin: "0px" }}>
                                    {type.description}
                                  </p>
                                  <DollarRating rating={type.rate} />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}

                  {currentProducts2.length > 0 && Designs2.length > 0 && (
                    // Render Designs2 when currentProducts2 and Designs2 are both not empty
                    <div className="row">
                      {Designs2.map((design) => {
                        return (
                          <div
                            key={design.id}
                            className="col-lg-3 col-md-6 col-sm-6 col-12 pb-3"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setDesign2(design.id, console.log(Design));
                              setChoosenDesignId(design.id);
                              setDesignPrice(design.price);
                            }}
                          >
                            <div className="content">
                              <h3 className="title">{design.name}</h3>
                            </div>
                            <div
                              style={{
                                backgroundImage: `url(${baseUrl}${design.image[0].url})`,
                                backgroundSize: "cover",
                              }}
                              className={
                                "service service__style--2" +
                                (Design2 === design.id ? " __activeCard" : "")
                              }
                            ></div>
                            <p className="description">{design.description}</p>
                            <p className="description">
                              {design.price + " " + "$"}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </TabPanel>

                {/* Estimated Date */}
                <TabPanel>
                  <div className="row">
                    {EstimatedData.map((estimated) => (
                      <div
                        key={estimated.id}
                        className="col-lg-4 col-md-6 col-sm-6 col-12"
                        style={{ cursor: "pointer" }}
                        onClick={() => setEstimatedDate(estimated.id)}
                      >
                        <div className="content">
                          <h3 className="title">{estimated.content}</h3>
                        </div>
                        <div
                          className={
                            "service service__style--2" +
                            (EstimatedDate === estimated.id
                              ? "  __activeCard"
                              : "")
                          }
                        >
                          <div className="icon">
                            <FiLayers />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </TabPanel>

                {/* Details */}
                <TabPanel>
                  <div className="row">
                    <div className="col-12">
                      <div className="contact-form--1">
                        <div className="container">
                          <div className="row row--35 align-items-start">
                            <div className="col-lg-12 order-2 order-lg-1">
                              <div className="section-title text-left mb--50">
                                <h3
                                  className="title"
                                  style={{
                                    lineHeight: 1.3,
                                  }}
                                >
                                  Project Details
                                </h3>
                              </div>
                              <div className="form-wrapper">
                                <div className="rn-form-group">
                                  <textarea
                                    name="details"
                                    placeholder="Enter Project Details"
                                    value={Form.details}
                                    onChange={handleInputChange}
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>

                {/* Financing */}
                <TabPanel>
                  <div className="row">
                    <div
                      className="col-lg-4 col-md-6 col-sm-6 col-12 "
                      style={{ cursor: "pointer" }}
                      onClick={() => setFinancing("Yes")}
                    >
                      <div
                        className={
                          "service service__style--2" +
                          (Financing === "Yes" ? "  __activeCard" : "")
                        }
                      >
                        <div className="content">
                          <h3 className="title">Yes</h3>
                        </div>
                        <div className="icon">
                          <img
                            src={YesImage}
                            alt="icon"
                            style={{
                              width: 25,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-4 col-md-6 col-sm-6 col-12"
                      style={{ cursor: "pointer" }}
                      onClick={() => setFinancing("No")}
                    >
                      <div
                        className={
                          "service service__style--2" +
                          (Financing === "No" ? "  __activeCard" : "")
                        }
                      >
                        <div className="content">
                          <h3 className="title">No</h3>
                        </div>
                        <div className="icon">
                          <img
                            src={NoImage}
                            alt="icon"
                            style={{
                              width: 25,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-4 col-md-6 col-sm-6 col-12"
                      style={{ cursor: "pointer" }}
                      onClick={() => setFinancing("Maybe")}
                    >
                      <div
                        className={
                          "service service__style--2" +
                          (Financing === "Maybe" ? "  __activeCard" : "")
                        }
                      >
                        <div className="content">
                          <h3 className="title">Maybe</h3>
                        </div>
                        <div className="icon">
                          <img
                            src={MaybeImage}
                            alt="icon"
                            style={{
                              width: 25,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>

                {/* Map View */}
                <TabPanel>
                  <div>
                    <div className="row">
                      <div className="col-12">
                        <h3 className="title" style={{ lineHeight: 1.3 }}>
                          Google Map With Address Input
                        </h3>
                      </div>
                    </div>
                    {area && (
                      <h3 className="title" style={{ lineHeight: 1.3 }}>
                        <p>Your Home Area: {area}</p>
                        <p>Your Total Cost: {area * designPrice} $</p>
                      </h3>
                    )}

                    <GoogleMapsComponent
                      center={mapCenter}
                      onMapClick={handleMapClick}
                      polygonCoords={coords}
                      mapTypeId="satellite"
                      getPolygon={getPolygon}
                    />
                  </div>
                </TabPanel>

                {/* Form */}
                <TabPanel>
                  <div className="row">
                    <div className="col-12">
                      <div className="contact-form--1">
                        <div className="container">
                          <div className="row row--35 align-items-start">
                            <div className="col-lg-6 order-2 order-lg-1">
                              <div className="section-title text-left mb--50">
                                <h3
                                  className="title"
                                  style={{
                                    lineHeight: 1.3,
                                  }}
                                >
                                  Your instant estimate is one click away.
                                </h3>
                              </div>
                              <div className="form-wrapper">
                                <form onSubmit={handleSubmit}>
                                  <div className="rn-form-group">
                                    <input
                                      type="text"
                                      name="name"
                                      placeholder="Enter Your Full Name"
                                      required
                                      value={Form.name}
                                      onChange={handleInputChange}
                                    />
                                  </div>

                                  <div className="rn-form-group">
                                    <input
                                      type="email"
                                      name="email"
                                      placeholder="Enter Your Email"
                                      required
                                      value={Form.email}
                                      onChange={handleInputChange}
                                    />
                                  </div>

                                  <div className="rn-form-group">
                                    <input
                                      type="tel"
                                      name="phone"
                                      placeholder="Enter Your Phone Number"
                                      required
                                      value={Form.phone}
                                      onChange={handleInputChange}
                                    />
                                  </div>

                                  <div className="rn-form-group">
                                    <button
                                      className="rn-button-style--2 btn-solid"
                                      type="submit"
                                      value="submit"
                                      name="submit"
                                      id="mc-embedded-subscribe"
                                    >
                                      Submit Now
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2">
                              <div className="thumbnail mb_md--30 mb_sm--30">
                                <img src={RoofImage} alt="trydo" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <div className="button-container">
                  <button
                    onClick={handleBack}
                    style={{ display: selectedTab === 0 ? "none" : "block" }}
                  >
                    Back
                  </button>
                  <button
                    onClick={handleNext}
                    style={{
                      display: selectedTab === tabCount - 1 ? "none" : "block",
                    }}
                  >
                    Next
                  </button>
                </div>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
      {/* End Tabs Area */}
      <Toaster />
    </div>
  );
}

export default TabsTwo;
