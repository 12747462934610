import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import styles from "./LocationMap.module.css";

const LocationMap = ({ location }) => {
  return (
    <div style={{ height: "400px", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyBGxdGnyVfi806eyfIwibnKAVFmKimfAjI" }}
        defaultCenter={location}
        defaultZoom={15}
      >
        <AnyReactComponent lat={location.lat} lng={location.lng} />
      </GoogleMapReact>
    </div>
  );
};

const AnyReactComponent = ({ link }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div
      className={styles.markerContainer}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {showTooltip && <div className={styles.tooltip}>Get Directions</div>}
      <a
        href="https://www.google.com/maps/dir/?api=1&destination=116%20Danny%20Ct,%20Louisville,%20KY%2040214,%20USAs"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="/assets/images/icons/map-marker.png"
          alt="Marker"
          style={{ width: "100%", height: "100%" }}
        />
      </a>
    </div>
  );
};

export default LocationMap;
