import React, { useState } from "react";
import Slider from "react-slick";
import classes from "./Card.module.css";
import DollarRating from "../tab/dollarRating/DollarRating";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Card = (props) => {
  const baseUrl = "https://be.stormsaverroofing.mohamedradwan.me/";
  

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true, 
    prevArrow: <button className={classes.prevArrow}>&#9664;</button>,
    nextArrow: <button className={classes.nextArrow}>&#9654;</button>,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  const renderImage = () => {
    if (props.showUrl) {
      return <img src={props.image} alt="card-image" />;
    } else {
      return <img src={`${baseUrl}${props.image}`} alt="card-image" />;
    }
  };

  const handleClick = () => {
    props.onClick(props.id, props.price);
  };


  return (
    <div
      className={`${classes.card}${props.isSelected ? ` ${classes.selected}` : ""}`}
      onClick={handleClick}
    >
      <h4 className={classes.title}>{props.title}</h4>

      {/* Conditionally render either a single image or a carousel */}
      {props.images ? (
        <div >
          <Slider {...settings}>
            {props.images.map((image, index) => (
              <img key={index} src={`${baseUrl}${image}`} alt={`card-image-${index}`} />
            ))}
          </Slider>
          
        </div>
      ) : (
        
        renderImage()
      )}

      <p className={classes.price}>{props.price}</p>
      {props.showDollar && <DollarRating rating={props.rate} />}
    </div>
  );
};

export default Card;
