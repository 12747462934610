import React, { Component } from "react";

const ServiceList = [
    {
        icon: '01',
        title: 'Zac, T',
        description: ' Roberto and his team did great getting my job done. They were able to get on my steep roof with no issues, where another roofing company was unable. Hard workers who know their craft well.'
    },
    {
        icon: '02',
        title: ' Angela, L',
        description: ' They were very professional and wonderful to work with. They made you feel like family. I would highly recommend them'
    },
    {
        icon: '03',
        title: 'Lisa, P',
        description: 'Services: Roof repair for storm & wind damage, Roof repair Positive: Professionalism, Punctuality, Quality, Responsiveness, Value'
    },
]

class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1" style={{ textAlign:"center"}}>
                                <div className="icon">
                                    <img src={`/assets/images/icons/icon-${val.icon}.png`} alt="Digital Agency"/>
                                </div>
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            </React.Fragment>
        )
    }
}
export default ServiceOne;