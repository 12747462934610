import React from "react";
import styles from "./PostsDisplay.module.css";
import { useHistory } from "react-router-dom";

const PostsDisplay = (props) => {
  const { postId, postImg, creatorImg, creatorName, creatorDate, creatorTime, postHeader, postContent, postViews, postComments } = props;

  const truncatedContent = postContent.substring(1, 150);

  const history = useHistory();

  const handleClick = () => {
    const postState = {
      postId,
      postImg,
      creatorImg,
      creatorName,
      creatorDate,
      creatorTime,
      postHeader,
      postContent,
      postViews,
      postComments
    };

    
  };

  return (
    <div className={styles.container} onClick={handleClick}>
      <div className={styles.leftContainer}>
        <img src={postImg} alt="Post Image" />
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.topContainer}>
          <div>
            <img src={creatorImg} alt="Creator Image" />
          </div>
          <div>
            <p>{creatorName}</p>
            <div className={styles.timeData}>
              <p>{creatorDate}</p>
              <p>{creatorTime}</p>
            </div>
          </div>
        </div>

        <h3>{postHeader}</h3>

        <p>{truncatedContent}...</p>

        <div className={styles.footer}>
          <p>{postViews} views</p>
          <p>{postComments} comments</p>
          <p></p>
        </div>
      </div>
    </div>
  );
};

export default PostsDisplay;