import React from "react";

function YouTubeVideo({ videoId }) {
  const embedUrl = `https://www.youtube.com/embed/${videoId}`;

  return (
    <div className="video-responsive" style={{ display: "flex", gap: "10px" }}>
      <iframe
        width="428"
        height="237"
        src={embedUrl}
        title="Oakridge® and Duration® Series Shingles Nailing Zone Performance | Owens Corning Roofing"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
  );
}

export default YouTubeVideo;
