import React, { useEffect, useState } from "react";
import useFetch from "../../../../hooks/useFetch";
import Card from "../Card";
import Brands from "./Brands";
import styles from "../building/Building.module.css";

const CurrentRoof = ({
  setCurrentMaterialId,
  setCurrentBrandId,
  setCurrentTypeId,
  setCurrentDesignId,
}) => {
  const [material, setMaterial] = useState([]);
  const [materialId, setMaterialId] = useState("");
  const [showBrands, setShowBrands] = useState(false);

  const { data: MaterialData, isLoading: MaterialIsLoading } =
    useFetch(`material`);

  useEffect(() => {
    const loadedProducts = [];

    if (MaterialData && !MaterialIsLoading) {
      for (const key in MaterialData) {
        loadedProducts.push({
          id: MaterialData[key].id,
          image: MaterialData[key].images[0].url,
          type: MaterialData[key].title,
        });
      }
      setMaterial(loadedProducts);
    } else {
      console.log("No products available");
    }
  }, [MaterialData, MaterialIsLoading]);

  const handleCardClick = (id) => {
    setMaterialId(id);
    setCurrentMaterialId(id);
    setShowBrands(true); // Show Brands when a card is clicked
  };

  const handleReset = () => {
    setMaterialId("");
    setShowBrands(false);
  };

  const test = material.map((m) => (
    <Card
      key={m.id}
      id={m.id}
      title={m.type}
      image={m.image}
      isSelected={m.id === materialId}
      onClick={() => handleCardClick(m.id)}
    />
  ));

  return (
    <div>
      <div>
        <button onClick={handleReset} className={styles.resetController}>
          Reset Material
        </button>
      </div>
      <div className={styles.container} style={{ display: "flex" }}>
        {showBrands ? (
          <Brands
            materialId={materialId}
            setCurrentBrandId={setCurrentBrandId}
            setCurrentTypeId={setCurrentTypeId}
            setCurrentDesignId={setCurrentDesignId}
          />
        ) : (
          test
        )}
      </div>
    </div>
  );
};

export default CurrentRoof;
