import React, { useState } from "react";
import PostFooter from "../PostFooter";
import DUMMY_POSTS from "../DUMMY_POSTS";
import styles from "../PostContent.module.css";
import RecentPosts from "../RecentPosts";

const PostOne = () => {
  const [selectedPost, setSelectedPost] = useState(null);
  const firstPost = DUMMY_POSTS[0];

  const onSelectPost = (post) => {
    setSelectedPost(post); // Update the selected post
  };

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.middleContainer}>
          <div className={styles.innerContainer}>
            <div className={styles.creatorInfo}>
              <img src={firstPost.creator.image} alt="Creator Image" />
              <p>
                <em>{firstPost.creator.name}</em>
              </p>
              <p>{firstPost.creator.date}</p>
              <p>{firstPost.creator.time}</p>
            </div>
            <h3>{firstPost.header}</h3>
            <img src={firstPost.postImage} alt="Post Image" />
            <div className={styles.description}>
              <p>
                When you think about saving money on your electric bill, you
                might consider energy-efficient appliances or better insulation
                for your home. However, there's another element that can make a
                surprising difference in your energy consumption: the color of
                your roof shingles. It might not be the first thing that comes
                to mind, but the color of your shingles can indeed affect your
                electric bill. In this blog, we'll delve into the science behind
                it and explore how choosing the right shingle color can help you
                save on energy costs. Understanding the Science
              </p>
              <p>
                Before we delve into the impact of shingle color on your
                electric bill, it's essential to understand how it all works.
                The key factor at play is the concept of solar reflectance and
                thermal emittance.
              </p>
              <ol>
                <li>
                  Solar Reflectance (Albedo): This refers to the ability of a
                  material to reflect sunlight, rather than absorbing it.
                  Materials with high solar reflectance can stay cooler because
                  they reflect more of the sun's energy. Light-colored shingles
                  typically have a higher solar reflectance.
                </li>
                <li>
                  Thermal Emittance: This relates to a material's ability to
                  release absorbed heat. Materials with high thermal emittance
                  can dissipate heat effectively, keeping the surface cooler.
                </li>
              </ol>
              <p>How Shingle Color Affects Your Electric Bill</p>

              <p>1. Light-Colored Shingles:</p>
              <img src="/assets/images/posts/post-one-content-image.png" />
              <p>
                Light-colored shingles, typically in shades of white, beige, or
                light gray, have a high solar reflectance. They reflect a
                significant portion of the sun's energy, preventing the roof
                from heating up excessively. As a result, your home's interior
                temperature remains lower, reducing the need for air
                conditioning during hot summer months. This can lead to
                substantial energy savings, ultimately lowering your electric
                bill.
              </p>
              <p>
                Conversely, dark-colored shingles like black or deep brown
                absorb more solar energy and have a lower solar reflectance.
                This absorption causes the roof to heat up, leading to higher
                indoor temperatures. As a result, your air conditioning system
                must work harder to maintain a comfortable temperature, which
                can significantly increase your energy consumption and electric
                bill.
              </p>
              <p>Consider Local Climate</p>
              <p>
                The impact of shingle color on your electric bill can vary
                depending on your geographical location and climate. In regions
                with predominantly hot and sunny weather, like the southern
                United States, the choice of lighter-colored shingles can make a
                more substantial difference. In contrast, in cooler climates,
                the effect may not be as pronounced, and other factors like
                insulation become more critical.
              </p>
              <p>
                When selecting shingle colors for your roof, consider the
                following factors:
              </p>
              <ol>
                <li>
                  Climate: If you live in a hot climate, opt for light-colored
                  shingles. In colder regions, you have more flexibility in your
                  color choice.
                </li>
                <li>
                  Aesthetics: Balance energy efficiency with your personal style
                  and the overall look of your home.
                </li>
                <li>
                  Local Regulations: Some areas may have building codes or
                  homeowners' association rules that restrict your color
                  choices, so be sure to check local regulations.
                </li>
                <li>
                  Energy-Efficient Materials: Consider using cool roofing
                  materials, which are designed to reflect more sunlight and
                  absorb less heat, regardless of color.
                </li>
              </ol>
              <p>Conclusion</p>
              <p>
                The color of your shingles may seem like a minor detail, but it
                can have a significant impact on your electric bill, especially
                in hot climates. By choosing the right shingle color with a high
                solar reflectance, you can keep your home cooler and reduce the
                need for constant air conditioning. This energy-efficient choice
                not only helps the environment but also saves you money in the
                long run. So, next time you need to replace your roof, think
                about the color of your shingles and make a smart choice for
                both your home and your wallet.
              </p>
            </div>
            <PostFooter />
          </div>
        </div>
      </div>
      <div className={styles.recentContainer}>
        <RecentPosts onSelectPost={onSelectPost} />
      </div>
    </div>
  );
};

export default PostOne;
