import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import React from "react";
const DUMMY_POSTS = [
  {
    id: "1",
    creator: {
      id: "c1",
      image: "/assets/images/icons/avatar.png",
      name: "Aguero",
      date: "Oct 16",
      time: "3 min read",
    },
    header: "How the Color of Shingles Can Impact Your Electric Bill",
    postImage:   "/assets/images/posts/post-1.png",
    content:
      "When you think about saving money on your electric bill, you might consider energy-efficient appliances or better insulation for your home. However, there's another element that can make a surprising difference in your energy consumption: the color of your roof shingles. It might not be the first thing that comes to mind, but the color of your shingles can indeed affect your electric bill. In this blog, we'll delve into the science behind it and explore how choosing the right shingle color can help you save on energy costs. ",
    
    footer: {
      views: 100,
      comments: 5,
    },
  },
  {
    id: "2",
    creator: {
      id: "c2",
      image: "/assets/images/icons/avatar.png",
      name: "Aguero",
      date: "Oct 16",
      time: "3 min read",
    },
    header: "Top Reasons to Skip DIY and Choose a Roofing Company for Gutter Maintenance",
    postImage: "/assets/images/posts/post-2.png",
    content: "When you think about saving money on your electric bill, you might consider energy-efficient appliances or better insulation for your home. However, there's another element that can make a surprising difference in your energy consumption: the color of your roof shingles. It might not be the first thing that comes to mind, but the color of your shingles can indeed affect your electric bill. In this blog, we'll delve into the science behind it and explore how choosing the right shingle color can help you save on energy costs.",
    linksIcon: [
      {
        icon: <FaFacebookF />,
        url: "#",
      },
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
    ],
    footer: {
      views: 150,
      comments: 10,
    },
  },
  {
    id: "3",
    creator: {
      id: "c3",
      image: "/assets/images/icons/avatar.png",
      name: "Aguero",
      date: "Oct 16",
      time: "3 min read",
    },
    header: "The Importance of Regular Roofing Services for Home Maintenance",
    postImage: "/assets/images/posts/post-3.png",
    content: "When you think about saving money on your electric bill, you might consider energy-efficient appliances or better insulation for your home. However, there's another element that can make a surprising difference in your energy consumption: the color of your roof shingles. It might not be the first thing that comes to mind, but the color of your shingles can indeed affect your electric bill. In this blog, we'll delve into the science behind it and explore how choosing the right shingle color can help you save on energy costs.",
    linksIcon: [
      {
        icon: <FaFacebookF />,
        url: "#",
      },
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
    ],
    footer: {
      views: 150,
      comments: 10,
    },
  },
  {
    id: "4",
    creator: {
      id: "c4",
      image: "/assets/images/icons/avatar.png",
      name: "Aguero",
      date: "Oct 16",
      time: "3 min read",
    },
    header: "Roofing Contractors: How To Identify a Good Roofing Contractor",
    postImage: "/assets/images/posts/post-4.png",
    content: "When you think about saving money on your electric bill, you might consider energy-efficient appliances or better insulation for your home. However, there's another element that can make a surprising difference in your energy consumption: the color of your roof shingles. It might not be the first thing that comes to mind, but the color of your shingles can indeed affect your electric bill. In this blog, we'll delve into the science behind it and explore how choosing the right shingle color can help you save on energy costs.",
    linksIcon: [
      {
        icon: <FaFacebookF />,
        url: "#",
      },
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
    ],
    footer: {
      views: 150,
      comments: 10,
    },
  },
  {
    id: "5",
    creator: {
      id: "c5",
      image: "/assets/images/icons/avatar.png",
      name: "Aguero",
      date: "Oct 16",
      time: "3 min read",
    },
    header: "Getting Insurance to Pay for Your Roof Replacement",
    postImage: "/assets/images/posts/post-5.png",
    content: "When you think about saving money on your electric bill, you might consider energy-efficient appliances or better insulation for your home. However, there's another element that can make a surprising difference in your energy consumption: the color of your roof shingles. It might not be the first thing that comes to mind, but the color of your shingles can indeed affect your electric bill. In this blog, we'll delve into the science behind it and explore how choosing the right shingle color can help you save on energy costs.",
    linksIcon: [
      {
        icon: <FaFacebookF />,
        url: "#",
      },
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
    ],
    footer: {
      views: 150,
      comments: 10,
    },
  },
];

export default DUMMY_POSTS;
