import React, { useState } from "react";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";
import styles from "./Footer.module.css";
import { Link } from "react-router-dom/cjs/react-router-dom";
import ServiceList from "../../home/storm-roofer/ServiceList";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/" },
  { Social: <FaTwitter />, link: "https://twitter.com/" },
];

const FooterTwo = () => {
  const emailAddress = "Stormsaver.ky@gmail.com";
  return (
    <div
      className="footer-style-2 ptb--30 bg_image bg_image--1"
      data-black-overlay="6"
    >
      <div className={styles.linkContainer}>
        <ul>
          <h3>Services</h3>
          {ServiceList.map((service) => (
            <a href={`/#${service.id}`}>
              <li key={service.id}>
                <p>{service.title}</p>
              </li>
            </a>
          ))}
        </ul>
        <ul>
          <h3>Extra</h3>
          <li>
            <p>Make a Payment</p>
          </li>
          <Link to="/storm-roofer/blog">
            <li>
              <p>Blog</p>
            </li>
          </Link>
          <a
            href="https://www.facebook.com/profile.php?id=100094294471143"
            target="_blank"
          >
            <li>
              <p>Facebook</p>
            </li>
          </a>
        </ul>
        <ul>
          <h3>Contact Us</h3>
          <li>
            <p>
              <span>Email:</span>{" "}
              <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
            </p>
          </li>
          <li>
            <p>
              <span>Contact Number:</span> +1 502 977 4935
            </p>
          </li>
          <a href="https://maps.app.goo.gl/RJvGBDdTi7vc7J7U7" target="_blank">
            <li>
              <p>
                <span>Address:</span> 116 Danny ct. Louisville, Kentucky USA
              </p>
            </li>
          </a>
        </ul>
      </div>
      <div className="wrapper plr--50 plr_sm--20">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="inner">
              <div className="logo text-center text-sm-left mb_sm--20">
                <a href="/home-one">
                  <img
                    src="/assets/images/logo/new-logo.png"
                    alt="Logo images"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="inner text-center">
              <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                {SocialShare.map((val, i) => (
                  <li key={i}>
                    <a href={`${val.link}`} target="_blank">
                      {val.Social}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 col-12">
            <div className="inner text-lg-right text-center mt_md--20 mt_sm--20">
              <div className="text">
                <p>Copyright © 2022 Rainbow-Themes. All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FooterTwo;
