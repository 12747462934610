// Brands.jsx
import React, { useEffect, useState } from "react";
import useFetch from "../../../../hooks/useFetch";
import Card from "../Card";
import styles from "../building/Building.module.css";
import Types from "./Types";

const Brands = ({ materialId, setCurrentBrandId , setCurrentTypeId , setCurrentDesignId }) => {
  const [brandData, setBrandData] = useState([]);
  const [brandId, setBrandId] = useState("");
  const [showTypes, setShowTypes] = useState(false);

  const { data: BrandData } = useFetch(`brand?material=${materialId}`);

  useEffect(() => {
    const loadedBrands = [];

    if (BrandData) {
      for (const key in BrandData) {
        loadedBrands.push({
          id: BrandData[key].id,
          image: BrandData[key].images[0].url,
          type: BrandData[key].title,
        });
      }
      setBrandData(loadedBrands);
    } else {
      console.log("No brands available");
    }
  }, [BrandData]);

  const handleCardClick = (id) => {
    setBrandId(id);
    setCurrentBrandId(id); // Call the function to set currentBrandId
    setShowTypes(true);
  };

  const brandCards = brandData.map((brand) => (
    <Card
      key={brand.id}
      id={brand.id}
      title={brand.type}
      image={brand.image}
      isSelected={brand.id === brandId}
      onClick={() => handleCardClick(brand.id)}
    />
  ));

  console.log(brandId);

  return (
    <div className={styles.container}>
      {showTypes ? (
        <Types
          materialId={materialId}
          brandId={brandId}
          setCurrentTypeId={setCurrentTypeId}
          setCurrentDesignId={setCurrentDesignId}
        />
      ) : (
        brandCards
      )}
    </div>
  );
};

export default Brands;
