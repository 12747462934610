import React, { useEffect, useState } from "react";
import "./Carousel.css";
import useFetch from "../../../../hooks/useFetch";

function DemoCarousel() {
    const imgs = [
        { id: 0, value: "https://wallpaperaccess.com/full/2637581.jpg" },
        { id: 1, value: "https://source.unsplash.com/user/c_v_r/1900x800" },
        { id: 2, value: "https://source.unsplash.com/user/c_v_r/100x100" },
    ];
    const [data, setData] = useState([]);
    const [wordData, setWordData] = useState({});
    const [val, setVal] = useState(0);

    const {
        data: DesignData,
        isLoading,
        error,
        reFetch,
    } = useFetch(`design?material=${1}&brand=${1}&getType=list`);

    const baseUrl = "https://be.stormsaverroofing.mohamedradwan.me/";

    useEffect(() => {
        if (DesignData && DesignData.length > 0) {
            const dataa = DesignData.map((item, index) => {
                const imageUrl = `${baseUrl}${item.image[0]?.url}`;
                return {
                    id: index,
                    value: imageUrl,
                };
            });

            setData(dataa);
            setWordData(dataa[0]); // Set the initial wordData here

            DesignData.map((item) => {
                console.log("Item", item);
            });
        }
    }, [DesignData]);

    const handleClick = (index) => {
        console.log(index);
        setVal(index);
        const wordSlider = data[index];
        setWordData(wordSlider);
    };

    const handleNext = () => {
        let index = val < data.length - 1 ? val + 1 : val;
        setVal(index);
        const wordSlider = data[index];
        setWordData(wordSlider);
    };

    const handlePrevious = () => {
        let index = val <= data.length - 1 && val > 0 ? val - 1 : val;
        setVal(index);
        const wordSlider = data[index];
        setWordData(wordSlider);
    };

    return (
        !isLoading &&
        data.length > 0 && (
            <div className="main">
                <button
                    className="btns"
                    onClick={handlePrevious}
                    style={{ display: "none" }}
                >
                    P
                </button>
                <img
                    src={wordData?.value}
                    height="750"
                    width="1250"
                    alt="Hello"
                    style={{ borderRadius: 20 }}
                />
                <button
                    className="btns"
                    onClick={handleNext}
                    style={{ display: "none" }}
                >
                    N
                </button>
                <div className="flex_row">
                    {data.map((data, i) => (
                        <div className="thumbnail" key={i}>
                            <img
                                className={
                                    wordData?.id === i
                                        ? "clicked"
                                        : "not-clicked"
                                }
                                src={data.value}
                                onClick={() => handleClick(i)}
                                height="70"
                                width="100"
                                alt="Hello"
                            />
                        </div>
                    ))}
                </div>
            </div>
        )
    );
}

export default DemoCarousel;
