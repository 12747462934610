import React from "react";
import styles from "./RecentPosts.module.css";
import { BsEye } from "react-icons/bs";
import { FaRegCommentAlt } from "react-icons/fa";
import { FcLike } from "react-icons/fc";
import DUMMY_POSTS from "./DUMMY_POSTS";

const RecentPosts = ({ onSelectPost }) => {
    
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <p>Recent Posts</p>
        <a href="/storm-roofer/blog">See All</a>
      </div>
      <div className={styles.cardsContainer}>
        {DUMMY_POSTS.slice(1, 4).map((post, index) => (
          <div key={index} className={styles.card} onClick={() => onSelectPost(post)}> 
            <img src={post.postImage} alt="image" />
            <h6>{post.header}</h6>
            <div className={styles.footer}>
              <div className={styles.footerIcons}>
                <BsEye />
                <FaRegCommentAlt />
              </div>
              <FcLike />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecentPosts;
